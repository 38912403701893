import API from "../../../../../global/api";


export const requestAddEmployee = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE",
    };
};
export const successAddEmployee = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE",
        payload: data,
    };
};
export const errorAddEmployee = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE",
    };
};
export const AddEmployeeApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddEmployee());
        API.post(`/employee`, data).then((res) => {
            let getData = res.data;
            handleVisible(res.data.message);
            dispatch(successAddEmployee(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddEmployee());
        })
    };
};


export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
}
export const GetEmployeeApi = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};


export const requestDeleteEmployee = () => {
    return {
        type: "REQUEST_DELETE_EMPLOYEE",
    };
};
export const successDeleteEmployee = (data) => {
    return {
        type: "SUCCESS_DELETE_EMPLOYEE",
        payload: data,
    };
};
export const errorDeleteEmployee = () => {
    return {
        type: "ERROR_DELETE_EMPLOYEE",
    };
};
export const DelateEmployeeApi = (id) => {
    return (dispatch) => {
        dispatch(requestDeleteEmployee());
        API.delete(`/employee/${id}`).then((res) => {
            dispatch(successDeleteEmployee());
        }).catch((error) => {
            dispatch(errorDeleteEmployee());
        })
    };
};


export const requestUpdateEmployee = () => {
    return {
        type: "REQUEST_UPDATE_EMPLOYEE",
    };
};
export const successUpdateEmployee = (data) => {
    return {
        type: "SUCCESS_UPDATE_EMPLOYEE",
        payload: data,
    };
};
export const errorUpdateEmployee = () => {
    return {
        type: "ERROR_UPDATE_EMPLOYEE",
    };
};
export const UpdateEmployeeApi = (newData, oldData) => {
    console.log('newData', newData);
    return (dispatch) => {
        dispatch(requestUpdateEmployee());
        API.put(`/employee/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateEmployee([newData]));
        }).catch((error) => {
            dispatch(errorUpdateEmployee());
        })
    };
};