import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import _ from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import moment from "moment";


const DetailViewCashService = (props) => {
    const location = useLocation();
    const fromPath = location.state?.from;

    useEffect(() => {
        props.setHeading("View Service Details");
    });

    const tableStyle = {
        borderCollapse: "collapse",
        width: "90%",
        margin: '0 auto',
    };
    const cellStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
    };

    return (
        <>
            {props.isFetchingCashServiceWithId ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <div>

                    <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                        <Link to={fromPath}>
                            <Button variant="contained" endIcon={<ArrowBackIcon />} size="large" style={{ fontWeight: 'bold' }}> BACK TO LIST </Button>
                        </Link>
                    </div>

                    <div style={{ backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px', marginBottom: '35px' }}>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Sr No: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].seqNumber}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Service Type: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].serviceType}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Status: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].status}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleNumber}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Made: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleMade}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Model: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleModel}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Kilometer: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleKilometer}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerName}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerNumber}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Email: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerEmail}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Date: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{moment(props.cashServiceDataListWithId[0].date).format("DD-MM-YYYY")}   </span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Type: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].typeValue}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer ID Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerIDNumber}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Employee: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].employeeDetails.name}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Business Location: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].businessLocationDetails.businessName}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Remarks: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].remarks}</span> </b></Col>
                            {/* <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Employee: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.cashServiceDataListWithId[0]) ? '' : props.cashServiceDataListWithId[0].employeeDetails.name}</span> </b></Col> */}
                            {/* <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Business Location: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.cashServiceDataListWithId[0]) ? '' : props.cashServiceDataListWithId[0].businessLocationDetails.businessName}</span> </b></Col> */}
                        </Row>
                    </div>


                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}> Delivery Pass Details </Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Owner Name : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.ownerName}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Residence ID : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.residenceID}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Reciver Name : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.reciverName}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Contact Number : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.contactNumber}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Deliver Date : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.deliverDate}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Kilometer : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].deliveryPassDetails.vehicleKilometer}</span> </b></Col>
                    </div>


                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Service Expense </Col>
                        <table style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#81abd3' }}>
                                    <th style={cellStyle}>Sr No</th>
                                    <th style={cellStyle}>Service Name</th>
                                    <th style={cellStyle}>Quantity</th>
                                    <th style={cellStyle}>Sale Price</th>
                                    <th style={cellStyle}>Total Sale Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isUndefined(props.cashServiceDataListWithId[0]) ? '' : props.cashServiceDataListWithId[0].serviceExpenseData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.productName}</td>
                                        <td style={cellStyle}>{item.quantity}</td>
                                        <td style={cellStyle}>{item.salePrice}</td>
                                        <td style={cellStyle}>{item.totalSalePrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Product Expense </Col>
                        <table style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#81abd3' }}>
                                    <th style={cellStyle}>Sr No</th>
                                    <th style={cellStyle}>Product Name</th>
                                    <th style={cellStyle}>Quantity</th>
                                    <th style={cellStyle}>Sale Price</th>
                                    <th style={cellStyle}>Total Sale Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isUndefined(props.cashServiceDataListWithId[0]) ? '' : props.cashServiceDataListWithId[0].productExpenseData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.productName}</td>
                                        <td style={cellStyle}>{item.quantity}</td>
                                        <td style={cellStyle}>{item.salePrice}</td>
                                        <td style={cellStyle}>{item.totalSalePrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}>Extra Service Expense </Col>
                        <table style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#81abd3' }}>
                                    <th style={cellStyle}>Sr No</th>
                                    <th style={cellStyle}>Description</th>
                                    <th style={cellStyle}>Quantity</th>
                                    <th style={cellStyle}>Sale Price</th>
                                    <th style={cellStyle}>Total Sale Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isUndefined(props.cashServiceDataListWithId[0]) ? '' : props.cashServiceDataListWithId[0].extraServiceExpenseData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.description}</td>
                                        <td style={cellStyle}>{item.quantity}</td>
                                        <td style={cellStyle}>{item.price}</td>
                                        <td style={cellStyle}>{item.totalPrice}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Advance Amount : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].advance}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Expense Total : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].totalExpense}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>VAT (5%) : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vatTotal}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Final Total : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].finalTotal}</span> </b></Col>
                    </div>


                    <div style={{ marginTop: '20px' }}>
                        {props.cashServiceDataListWithId[0].images.map((image, index) => (
                            <div style={{ float: 'left', marginRight: '15px' }}>
                                <a href={image.file} target="blank"><img src={image.file} alt={`alt`} width={"160px"} height={"160px"} /></a>
                            </div>
                        ))}
                    </div>

                </div>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataListWithId: state.CashServiceReducer.cashServiceDataListWithId,
    isFetchingCashServiceWithId: state.CashServiceReducer.isFetchingCashServiceWithId,
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailViewCashService);