const initialState = {
  isLogin: false,
  loginUserId: "",
  unableLogin: true,
  userDetails: {},
  moduleKey: '',
  heading: "Garage",
  isWrongCredentials: false,
  message: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case "LOGIN_USER_REQUEST":
      return { ...state, isLogin: true, isWrongCredentials: false };
    case "LOGIN_USER_SUCCESS":
      return { ...state, isLogin: false, userDetails: action.payload, isWrongCredentials: false };
    case "LOGIN_USER_ERROR":
      return { ...state, isLogin: false, isWrongCredentials: true, message: action.payload };


    case "CHANGE_IS_WRONG":
      return { ...state, isWrongCredentials: false };


    case "SET_MODULE_KEY":
      return { ...state, moduleKey: action.payload };


    case "SET_HEADING_KEY":
      return { ...state, heading: action.payload };

    default:
      return state;
  }
};

export default userReducer;
