const initialState = {
    searchInventoryLedgerList: [],
    isFetchingSearchInventoryLedger: false,
};

const StoreLegderReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_SEARCH_PRODUCT":
            return { ...state, isFetchingSearchInventoryLedger: true };
        case "SUCCESS_SEARCH_PRODUCT":
            return { ...state, searchInventoryLedgerList: action.payload, isFetchingSearchInventoryLedger: false, };
        case "ERROR_SEARCH_PRODUCT":
            return { ...state, isFetchingSearchInventoryLedger: false };


        default:
            return state;
    }
};
export default StoreLegderReducer;
