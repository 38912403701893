import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateServiceStatusApi, DeleteInsuranceServiceApi, InsuranceServiceEnglishPDF, GetInsuranceServiceWithIdApi, InsuranceServiceArabicPDF } from "./insuranceServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import moment from "moment";
const tableRef = React.createRef()


const InspectionList = (props) => {
    const navigate = useNavigate();
    const [openPDF, setOpenPDF] = React.useState(false);
    const handleOpenPDF = () => { setOpenPDF(true) };
    const handleClosePDF = () => setOpenPDF(false);
    const [seletcedDataPDF, setSelectedDataPDF] = React.useState();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [seletcedData, setSelectedData] = React.useState();
    const style = {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '95%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const stylePDF = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function PrintEnglishPDF() {
        props.InsuranceServiceEnglishPDF(seletcedDataPDF)
        handleClosePDF()
    }
    function PrintArabicPDF() {
        props.InsuranceServiceArabicPDF(seletcedDataPDF)
        handleClosePDF()
    }

    useEffect(() => {
        props.setHeading("Inspection");
    }, []);

    return (
        <>
            {props.isFetchingInsuranceService ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Inspection List"
                            columns={[
                                { title: 'Invoice No', field: 'seqNumber' },
                                { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                                { title: "Insurance Company", field: "incuranceCompanyDetails.companyName", editable: 'never' },
                                { title: "Date", field: "date", render: rowData => moment(rowData.date).format("DD-MM-YYYY") },
                                { title: "Status", field: "status" },
                                { title: "Vehicle Number", field: "vehicleNumber" },
                                { title: "Vehicle Made", field: "vehicleMade" },
                                { title: "Vehicle Model", field: "vehicleModel" },
                                { title: "Customer Name", field: "customerName" },
                                { title: "Customer Number", field: "customerNumber" },
                                { title: "Customer Email", field: "customerEmail" },
                                { title: "Employee Name", field: "employeeDetails.name" },
                                { title: "Remarks", field: "remarks" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/insuranceService?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data.sort((a, b) => b.seqNumber - a.seqNumber),
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteInsuranceServiceApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Refresh Data',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                },
                                (rowData) => ({
                                    disabled: rowData.status === 'Work In Process' ? false : true,
                                    icon: () => <TaskAltIcon />,
                                    tooltip: "Inspection Approved",
                                    onClick: (event, rowData) => {
                                        const status = 'Inspection Approved'
                                        props.UpdateServiceStatusApi(status, rowData._id);
                                        tableRef.current && tableRef.current.onQueryChange();
                                    },
                                }),
                                (rowData) => ({
                                    disabled: rowData.status === 'Work In Process' ? false : true,
                                    icon: () => <BlockIcon />,
                                    tooltip: "Inspection Rejected",
                                    onClick: (event, rowData) => {
                                        const status = 'Inspection Rejected'
                                        props.UpdateServiceStatusApi(status, rowData._id);
                                        tableRef.current && tableRef.current.onQueryChange();
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Service",
                                    onClick: (event, rowData) => {
                                        // props.InsuranceServiceEnglishPDF(rowData)
                                        setSelectedDataPDF(rowData)
                                        handleOpenPDF()
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <VisibilityIcon />,
                                    tooltip: "Preview Service",
                                    onClick: (event, rowData) => {
                                        setSelectedData(rowData)
                                        handleOpen()
                                        // props.GetInsuranceServiceWithIdApi(rowData._id)
                                        // navigate("/mainService/Insurance/DetailViewIncService")
                                    },
                                }),
                                (rowData) => ({
                                    disabled: rowData.status === 'Work In Process' ? false : true,
                                    icon: () => <BorderColorIcon />,
                                    tooltip: "Update Inspection",
                                    onClick: (event, rowData) => {
                                        navigate("/mainService/Insurance/EditExpense");
                                        props.GetInsuranceServiceWithIdApi(rowData._id)
                                    },
                                }),
                            ]}
                        />
                    </div>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div style={{ overflow: 'hidden', width: '100%', backgroundColor: '#aed6ae', padding: '20px' }}>
                                <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px' }}>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Made: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleMade}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Model: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.vehicleModel}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerName}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Email: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(seletcedData) ? '' : seletcedData.customerEmail}</span> </b></Col>
                                    </Row>
                                </div>

                                <Row style={{ marginTop: '10px' }}>
                                    <Col xl="4" lg="4" md="4" sm="4" style={{ borderRightStyle: "solid" }} >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Product </Col>
                                        <Row>
                                            <Col xl="1" lg="1" md="1" sm="1"><h6><b>Sr</b></h6></Col>
                                            <Col xl="5" lg="5" md="5" sm="5"><h6><b>Service</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Quantity</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Price</b></h6></Col>
                                        </Row>
                                        {_.isUndefined(seletcedData) ? '' : seletcedData.productExpenseData.map((item, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="1" lg="1" md="1" sm="1">{i + 1}</Col>
                                                        <Col xl="5" lg="5" md="5" sm="5">{item.productName}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.quantity}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.salePrice}</Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                        <Col style={{ marginTop: '15px', fontWeight: 'bold' }}>Product Total: {Math.round(_.isUndefined(seletcedData) ? '' : _.sumBy(seletcedData.productExpenseData, 'totalSalePrice') * 100) / 100}</Col>
                                    </Col>

                                    <Col xl="4" lg="4" md="4" sm="4" style={{ borderRightStyle: "solid" }}>
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Service </Col>
                                        <Row>
                                            <Col xl="1" lg="1" md="1" sm="1"><h6><b>Sr</b></h6></Col>
                                            <Col xl="5" lg="5" md="5" sm="5"><h6><b>Service</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Quantity</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Price</b></h6></Col>
                                        </Row>
                                        {_.isUndefined(seletcedData) ? '' : seletcedData.serviceExpenseData.map((item, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="1" lg="1" md="1" sm="1">{i + 1}</Col>
                                                        <Col xl="5" lg="5" md="5" sm="5">{item.productName}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.quantity}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.salePrice}</Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                        <Col style={{ marginTop: '15px', fontWeight: 'bold' }}>Service Total: {Math.round(_.isUndefined(seletcedData) ? '' : _.sumBy(seletcedData.serviceExpenseData, 'totalSalePrice') * 100) / 100}</Col>
                                    </Col>

                                    <Col xl="4" lg="4" md="4" sm="4" >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}>Extra Service Expense</Col>
                                        <Row>
                                            <Col xl="1" lg="1" md="1" sm="1"><h6><b>Sr</b></h6></Col>
                                            <Col xl="5" lg="5" md="5" sm="5"><h6><b>Description</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Quantity</b></h6></Col>
                                            <Col xl="3" lg="3" md="3" sm="3"><h6><b>Price</b></h6></Col>
                                        </Row>
                                        {_.isUndefined(seletcedData) ? '' : seletcedData.extraServiceExpenseData.map((item, i) => {
                                            return (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col xl="1" lg="1" md="1" sm="1">{i + 1}</Col>
                                                        <Col xl="5" lg="5" md="5" sm="5">{item.description}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.quantity}</Col>
                                                        <Col xl="3" lg="3" md="3" sm="3">{item.price}</Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        })}
                                        <Col style={{ marginTop: '15px', fontWeight: 'bold' }}>Service Total: {Math.round(_.isUndefined(seletcedData) ? '' : _.sumBy(seletcedData.extraServiceExpenseData, 'totalPrice') * 100) / 100}</Col>
                                    </Col>

                                    <Row style={{ fontWeight: 'bold' }}>Expense Total: {_.isUndefined(seletcedData) ? '' : seletcedData.totalExpense}</Row>
                                    <Row style={{ fontWeight: 'bold' }}>Vat: {_.isUndefined(seletcedData) ? '' : seletcedData.vatTotal}</Row>
                                    <Row style={{ fontWeight: 'bold' }}>Final Total: {_.isUndefined(seletcedData) ? '' : seletcedData.finalTotal}</Row>

                                    <div style={{ marginTop: '20px' }}>
                                        {_.isEmpty(seletcedData) ? '' : seletcedData.images.map((image, index) => (
                                            <div style={{ float: 'left', marginRight: '15px' }}>
                                                <a href={image.file} target="blank"><img src={image.file} alt={`alt`} width={"130px"} height={"130px"} /></a>
                                            </div>
                                        ))}
                                    </div>

                                </Row>
                            </div>
                        </Box>
                    </Modal>

                    <Modal
                        open={openPDF}
                        onClose={handleClosePDF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePDF}>
                            <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Please Select Language </Col>
                            <Row>
                                <Col>
                                    <Button variant="contained" size="large" onClick={PrintEnglishPDF}> English </Button>
                                </Col>
                                <Col>
                                    <Button variant="contained" size="large" onClick={PrintArabicPDF} > Arabic </Button>
                                </Col>
                            </Row>
                        </Box>
                    </Modal>

                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteInsuranceServiceApi: (productId) => dispatch(DeleteInsuranceServiceApi(productId)),
        InsuranceServiceEnglishPDF: (data) => dispatch(InsuranceServiceEnglishPDF(data)),
        InsuranceServiceArabicPDF: (data) => dispatch(InsuranceServiceArabicPDF(data)),
        GetInsuranceServiceWithIdApi: (id) => dispatch(GetInsuranceServiceWithIdApi(id)),
        UpdateServiceStatusApi: (newData, oldData) => dispatch(UpdateServiceStatusApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    insuranceServiceDataList: state.InsuranceServiceReducer.insuranceServiceDataList,
    isFetchingInsuranceService: state.InsuranceServiceReducer.isFetchingInsuranceService,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionList);