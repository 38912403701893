import * as React from 'react';
import { Image } from "react-bootstrap";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { forwardRef } from 'react';
import Swal from "sweetalert";
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import Logo from '../../../Images/logo.png';
import { connect } from 'react-redux';
import { getLogin, changeIsWrong } from "../../user_views/accounts/account.actions"
import _ from 'lodash';
import LoginIcon from '@mui/icons-material/Login';


export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color
        }), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}
const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...other}
  />
));
Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number,
};


function Login(props) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [userName, setUserName] = React.useState("");
  const [pass, setPassword] = React.useState("");
  const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);

  const handleSubmit = () => {
    props.getLogin({
      userName: userName,
      password: pass,
    },
      handleDangerVisible
    )
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField
          name="User Name"
          label="User Name *"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          sx={{ color: '#212b36', fontSize: '1rem', fontWeight: 'bold' }}
        />
        <TextField
          name="password"
          label="Password *"
          type={showPassword ? 'text' : 'password'}
          value={pass}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ color: '#212b36', fontSize: '1rem', fontWeight: 'bold' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3, color: '#004aad', fontWeight: '600', fontSize: '0.875rem' }}>
        <Link variant="subtitle2" underline="hover">
          <b>Forgot password?</b>
        </Link>
      </Stack>

      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        {_.isEmpty(userName) || _.isEmpty(pass) ? (
          <Button variant="contained" style={{ width: '100%', borderRadius: '8px', fontFamily: '"Public Sans", sans-serif', fontSize: '0.9375rem' }} endIcon={<LoginIcon />} size="large" disabled> LogIn </Button>
        ) : (
          <Button variant="contained" style={{ width: '100%', color: '#ffffff', backgroundColor: '#212b36', borderRadius: '8px', fontFamily: '"Public Sans", sans-serif', fontSize: '0.9375rem' }} endIcon={<LoginIcon />} size="large" onClick={handleSubmit}> LogIn </Button>
        )}
      </div>

    </>
  );
  return (
    <div style={{ backgroundColor: '#f5f4f4', paddingTop: '20px', paddingBottom: '80px' }}>
      <Box
        sx={{
          ...bgGradient({
            color: '#f5f4f4',
          }),
          height: 1,
        }}
      >
        <Image src={Logo} style={{ height: '15vh', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }} />
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
              borderRadius: '16px',
            }}
          >
            <Typography variant="h4" style={{ fontSize: '1.5rem' }}><b> WelCome to <span style={{ color: '#004aad' }}> Alfarsi Group </span> </b> </Typography>
            <Typography variant="body2" sx={{ mt: 2, mb: 3 }}> <q> Delivering Excellence, Driving Innovation </q></Typography>
            <Stack direction="row" spacing={2}>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                target="_blank"
                // href="https://www.facebook.com/reporterp"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                <Iconify icon="eva:facebook-fill" color="#1877F2" />
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                <Iconify icon="entypo-social:youtube" color='#FF0000'></Iconify>
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                {/* <Iconify icon="entypo-social:instagram" color='#feda75'></Iconify> */}
                <Iconify icon="entypo-social:linkedin" color='#0077B5'></Iconify>
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            {renderForm}
          </Card>
        </Stack>
      </Box>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getLogin: (data, handleDangerVisible) => dispatch(getLogin(data, handleDangerVisible)),
    closeSnack: () => dispatch(changeIsWrong())
  }
}
const mapStateToProps = (state, ownProps, number) => ({
  isLogin: state.userReducer.isLogin,
  isWrongCredentials: state.userReducer.isWrongCredentials,
  message: state.userReducer.message,
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);