import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import { AddServiceApi } from "./serviceAction";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { useTranslation } from "react-i18next";
import '../../../../../il8n';


const AddCompany = (props) => {
  const { t, i18n } = useTranslation();
  const [serviceName, setServiceName] = useState("");
  const [description, setServiceDesc] = useState("");
  const [price, setPrice] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
  function saveService() {
    props.AddServiceApi({
      serviceName: serviceName,
      description: description,
      price: price,
    },
      handleVisible,
      handleDangerVisible
    );
    setServiceName("")
    setServiceDesc("")
    setPrice("")
  }

  useEffect(() => {
    props.setHeading("Add Serivce");
  }, []);

  return (
    <>
      {props.isAddingService ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>{t('ServiceName')} *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Service Name"
                        onChange={(e) => setServiceName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="garana" >
                        <Form.Label><b>{t('Price')} *</b></Form.Label>
                        <Form.Control
                          type="Number"
                          placeholder="Price"
                          onChange={(e) => setPrice(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="garana" >
                        <Form.Label><b>{t('Description')} </b></Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Description"
                          onChange={(e) => { setServiceDesc(e.target.value) }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>

                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                  {isEmpty(serviceName) || isEmpty(price) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={saveService}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddServiceApi: (data, handleVisible, handleDangerVisible) => dispatch(AddServiceApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  serviceList: state.ServiceReducer.serviceList,
  isAddingService: state.ServiceReducer.isAddingService,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);