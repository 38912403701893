import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { AddCashExpenseApi, setEditFields } from "./cashServiceAction";
import { getProductData } from "../../MasterControl/Product/productAction";
import { GetServiceApi } from "../../MasterControl/Service/serviceAction";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SendIcon from '@mui/icons-material/Send';
import SaveButton from '@mui/material/Button';


const EditExpense = (props) => {
    const navigate = useNavigate();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputFieldProduct, setInputFieldProduct] = useState([{
        productId: 0,
        productName: '',
        quantity: '',
        salePrice: 0,
        totalSalePrice: 0,
    }]);
    const [inputFieldService, setInputFieldService] = useState([{
        productId: 0,
        productName: '',
        quantity: '',
        salePrice: 0,
        totalSalePrice: 0,
    }]);


    function SaveExpense() {
        props.AddCashExpenseApi({
            cashServiceId: props.cashServiceDataListWithId[0]._id,
            productExpenseData: props.cashServiceDataListWithId[0].productExpenseData,
            serviceExpenseData: props.cashServiceDataListWithId[0].serviceExpenseData,
        },
            handleVisible,
            handleDangerVisible
        );
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
            navigate("/mainService/Cash/CompleteList");
        }, 2000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };


    const handleChangeProduct = (e, index, type, itemId) => {
        let z = _.find(props.productDataList, (x) => x._id == e.value);
        // const list = [...inputFieldProduct];
        const list = [...props.cashServiceDataListWithId[0].productExpenseData];
        if (type === "intname") list[index]["productId"] = e.value;
        if (type === "intname") list[index]["productName"] = e.label;
        if (type === "intname") list[index]["salePrice"] = Number(z.salePrice);
        if (type === "intquantity") list[index]["quantity"] = Number(e.target.value);
        if (type === 'intquantity' || type === 'intname') list[index]['totalSalePrice'] = Number(Math.round((list[index]['salePrice'] * list[index]['quantity']) * 100) / 100);

        setInputFieldProduct(list);
        props.setEditFields({
            key: "productExpenseData",
            value: list
        })
    };
    const getProductList = !_.isEmpty(props.productDataList) && props.productDataList.map((x) => {
        let data = { value: x._id, label: `${x.name}` };
        return data;
    });
    const handleAddInputProduct = () => {
        props.setEditFields({
            key: "productExpenseData",
            value: [
                ...props.cashServiceDataListWithId[0].productExpenseData,
                { productId: 0, productName: "", quantity: "", salePrice: 0 },
            ]
        })
    };
    const handleRemoveInputProduct = (index) => {
        const list = [...props.cashServiceDataListWithId[0].productExpenseData];
        list.splice(index, 1);
        props.setEditFields({
            key: "productExpenseData",
            value: list
        })
    };


    const handleChangeService = (e, index, type, itemId) => {
        let z = _.find(props.serviceDataList, (x) => x._id == e.value);
        const list = [...props.cashServiceDataListWithId[0].serviceExpenseData];
        if (type === "intname") list[index]["productId"] = e.value;
        if (type === "intname") list[index]["productName"] = e.label;
        if (type === "intname") list[index]["salePrice"] = Number(z.price);
        if (type === "intquantity") list[index]["quantity"] = Number(e.target.value);
        if (type === 'intquantity' || type === 'intname') list[index]['totalSalePrice'] = Number(Math.round((list[index]['salePrice'] * list[index]['quantity']) * 100) / 100);

        setInputFieldService(list);
        props.setEditFields({
            key: "serviceExpenseData",
            value: list
        })
    };
    const getServiceList = !_.isEmpty(props.serviceDataList) && props.serviceDataList.map((x) => {
        let data = { value: x._id, label: `${x.serviceName}` };
        return data;
    });
    const handleAddInputService = () => {
        props.setEditFields({
            key: "serviceExpenseData",
            value: [
                ...props.cashServiceDataListWithId[0].serviceExpenseData,
                { productId: 0, productName: "", quantity: "", salePrice: 0 },
            ]
        })
    };
    const handleRemoveInputService = (index) => {
        const list = [...props.cashServiceDataListWithId[0].serviceExpenseData];
        list.splice(index, 1);
        props.setEditFields({
            key: "serviceExpenseData",
            value: list
        })
    };


    useEffect(() => {
        props.setHeading("Edit Expense");
        props.getProductData()
        props.GetServiceApi()
    }, []);

    return (<>
        {props.isFetchingCashServiceWithId ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (<>
            <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px' }}>
                <Row>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleNumber}</span> </b></Col>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Made: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleMade}</span> </b></Col>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Vehicle Model: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].vehicleModel}</span> </b></Col>
                </Row>
                <Row>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerName}</span> </b></Col>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerNumber}</span> </b></Col>
                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Email: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.cashServiceDataListWithId[0].customerEmail}</span> </b></Col>
                </Row>
            </div>


            <Row style={{ marginTop: '30px' }}>
                <Col xl="6" lg="6" md="6" sm="6" style={{ borderRightStyle: "solid" }} >
                    <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Product </Col>
                    {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].productExpenseData.map((item, i) => {
                        console.log(item, "ppppppppppppppppppppppppppp")
                        return (
                            <React.Fragment key={i}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="garana">
                                            <Form.Label><b>Select Product *</b></Form.Label>
                                            <Select
                                                placeholder="Select Product"
                                                name="productId"
                                                value={{ label: item.productName }}
                                                onChange={(e) => handleChangeProduct(e, i, "intname")}
                                                options={getProductList}
                                                isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldProduct, (x) => x.productId == option.value))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Add Quantity *</b> </Form.Label>
                                            <Form.Control
                                                type="number"
                                                placeholder="Add Quantity"
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={(e) => handleChangeProduct(e, i, "intquantity")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Sale Price </b></Form.Label>
                                            <Form.Control
                                                disabled
                                                type="number"
                                                placeholder="Sale Price"
                                                name="salePrice"
                                                value={item.salePrice}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].productExpenseData.length - 1 === i && (
                                            <Button title="Add Product" onClick={handleAddInputProduct} style={{ float: "right" }} >
                                                <AddIcon />
                                            </Button>
                                        )}
                                        {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].productExpenseData.length !== 1 && (
                                            <Button title="Remove Product" onClick={() => handleRemoveInputProduct(i)} style={{ float: "right", marginRight: "1%" }}>
                                                <RemoveIcon />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </React.Fragment>)
                    })}
                </Col>


                <Col xl="6" lg="6" md="6" sm="6" >
                    <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Service </Col>
                    {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].serviceExpenseData.map((item, i) => {
                        console.log(item, "sssssssssssssssssssssssssssss")
                        return (
                            <React.Fragment key={i}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="garana">
                                            <Form.Label> <b>Select Service *</b> </Form.Label>
                                            <Select
                                                placeholder="Select Service"
                                                name="productId"
                                                value={{ label: item.productName }}
                                                onChange={(e) => handleChangeService(e, i, "intname")}
                                                options={getServiceList}
                                                isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldService, (x) => x.productId == option.value))}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Add Quantity *</b> </Form.Label>
                                            <Form.Control
                                                // min={0}
                                                type="number"
                                                placeholder="Add Quantity"
                                                name="quantity"
                                                value={item.quantity}
                                                onChange={(e) => handleChangeService(e, i, "intquantity")}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Sale Price </b></Form.Label>
                                            <Form.Control
                                                disabled
                                                type="number"
                                                placeholder="Sale Price"
                                                name="salePrice"
                                                value={item.salePrice}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].serviceExpenseData.length - 1 === i && (
                                            <Button title="Add Product" onClick={handleAddInputService} style={{ float: "right" }} >
                                                <AddIcon />
                                            </Button>
                                        )}
                                        {!_.isEmpty(props.cashServiceDataListWithId) && props.cashServiceDataListWithId[0].serviceExpenseData.length !== 1 && (
                                            <Button title="Remove Product" onClick={() => handleRemoveInputService(i)} style={{ float: "right", marginRight: "1%" }}>
                                                <RemoveIcon />
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </React.Fragment>)
                    })}
                </Col>

                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <SaveButton variant="contained" endIcon={<SendIcon />} onClick={SaveExpense} size="large" > SAVE </SaveButton>
                </div>

            </Row>
        </>)}
    </>)

}


const mapDispatchToProps = (dispatch) => {
    return {
        AddCashExpenseApi: (data, handleVisible, handleDangerVisible) => dispatch(AddCashExpenseApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        getProductData: () => dispatch(getProductData()),
        GetServiceApi: () => dispatch(GetServiceApi()),
        setEditFields: (data) => dispatch(setEditFields(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataListWithId: state.CashServiceReducer.cashServiceDataListWithId,
    isFetchingCashServiceWithId: state.CashServiceReducer.isFetchingCashServiceWithId,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    serviceDataList: state.ServiceReducer.serviceDataList,
    isFetchingService: state.ServiceReducer.isFetchingService,
});


export default connect(mapStateToProps, mapDispatchToProps)(EditExpense);