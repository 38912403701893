import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteWarehouseApi, updateWarehouseApi } from "./BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";
// import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';


const WarehouseTableList = (props) => {

    useEffect(() => {
        props.setHeading("Businnes Location Details");
    }, []);

    return (
        <>
            {props.isFetchingWarehouseList ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Businnes Location Details"
                            columns={[
                                { title: 'Company', field: 'companyDetails.companyName', editable: 'never' },
                                { title: 'Business Location', field: 'businessName' },
                                { title: 'Location', field: 'location' },
                                { title: 'City', field: 'city' },
                                { title: 'Zip Code', field: 'zipCode' },
                                { title: 'State', field: 'state' },
                                { title: 'Contact', field: 'phone' },
                                { title: 'Description', field: 'description' },
                                { title: 'Manager', field: 'manager' },
                                { title: 'Date', field: 'createdAt', type: 'date', editable: 'never', dateSetting: { locale: "en-GB" } },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/businesses?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.updateWarehouseApi(newData, oldData)
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteWarehouseApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        deleteWarehouseApi: (_Id) => dispatch(deleteWarehouseApi(_Id)),
        updateWarehouseApi: (newData, oldData) => dispatch(updateWarehouseApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    warehouseList: state.WarehouseReducer.warehouseList,
    isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
});

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseTableList);