import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { GetCashServiceApi, DeliveryPassReportApi } from "./cashServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select from "react-select";
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Spinner from "react-bootstrap/Spinner";
import '../../../../../il8n';


const DeliveryPassReport = (props) => {
    const { t, i18n } = useTranslation();
    const [mainServiceId, setMainServiceId] = useState();
    const [mainServiceName, setMainServiceName] = useState("");
    const [openPDF, setOpenPDF] = React.useState(false);
    const handleOpenPDF = () => { setOpenPDF(true) };
    const handleClosePDF = () => setOpenPDF(false);
    const stylePDF = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleChangeMainService = (selectedOption) => {
        setMainServiceId(selectedOption.value);
        setMainServiceName(selectedOption.label);
    };
    const filterMainServiceList = _.filter(props.cashServiceDataList, { status: 'Bill Paid' }).sort((a, b) => b.seqNumber - a.seqNumber);
    const getMainServiceList = !_.isEmpty(filterMainServiceList) && filterMainServiceList.map((x) => {
        let data = { value: x._id, label: ` ${x.seqNumber} / ${x.vehicleNumber} / ${x.vehicleMade} / ${x.customerName}` };
        return data;
    });

    function PrintEnglishPDF() {
        props.DeliveryPassReportApi({
            mainServiceId: mainServiceId,
            type: 'English'
        });
        handleClosePDF()
    }
    function PrintArabicPDF() {
        props.DeliveryPassReportApi({
            mainServiceId: mainServiceId,
            type: 'Arabic'
        });
        handleClosePDF()
    }

    useEffect(() => {
        props.GetCashServiceApi()
        props.setHeading("Delivery Pass Report");
    }, []);

    return (<>
        {props.isFetchingCashService ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('SelectService')} *</b></Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(filterMainServiceList)}
                                    placeholder="Select Service"
                                    onChange={handleChangeMainService}
                                    options={getMainServiceList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '40px', textAlign: 'center' }}>
                        {_.isEmpty(mainServiceId) ? (
                            <Button variant="contained" endIcon={<PrintIcon />} size="large" disabled> GENRATE PDF </Button>
                        ) : (
                            <Button variant="contained" endIcon={<PrintIcon />} size="large" onClick={(e) => handleOpenPDF()}> GENRATE PDF </Button>
                        )}
                    </div>

                </div>

                <Modal
                    open={openPDF}
                    onClose={handleClosePDF}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={stylePDF}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Please Select Language </Col>
                        <Row>
                            <Col>
                                <Button variant="contained" size="large" onClick={PrintEnglishPDF}> English</Button>
                            </Col>
                            <Col>
                                <Button variant="contained" size="large" onClick={PrintArabicPDF} >Arabic</Button>
                            </Col>
                        </Row>
                    </Box>
                </Modal>

            </Container>
        )}
    </>);
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetCashServiceApi: () => dispatch(GetCashServiceApi()),
        DeliveryPassReportApi: (id) => dispatch(DeliveryPassReportApi(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
}
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataList: state.CashServiceReducer.cashServiceDataList,
    isFetchingCashService: state.CashServiceReducer.isFetchingCashService,
    cashServiceDataListWithId: state.CashServiceReducer.cashServiceDataListWithId,
    isFetchingCashServiceWithId: state.CashServiceReducer.isFetchingCashServiceWithId,
})

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPassReport);
