const initialState = {
  warehouseList: [],
  warehouse: [],
  warehouseListWithResId: [],
  isAddingWarehouse: false,
  dataAddedWarehouse: false,
  dataNotAddedWarehouse: false,
  isFetchingWarehouseList: false,
  isFetchingWarehouseListWithResId: false,
};

const WarehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_WAREHOUSE":
      return {
        ...state,
        isAddingWarehouse: true,
        dataAddedWarehouse: false,
        dataNotAddedWarehouse: false,
      };
    case "SUCCESS_ADD_WAREHOUSE":
      return {
        ...state,
        warehouse: action.payload,
        isAddingWarehouse: false,
        dataAddedWarehouse: true,
        dataNotAddedWarehouse: false,
      };
    case "ERROR_ADD_WAREHOUSE":
      return {
        ...state,
        isAddingWarehouse: false,
        dataAddedWarehouse: false,
        dataNotAddedWarehouse: true
      };


    case "REQUEST_GET_ALL_WAREHOUSE":
      return {
        ...state,
        isFetchingWarehouseList: true,
        dataNotAddedWarehouse: false,
      };
    case "SUCCESS_GET_ALL_WAREHOUSE":
      return {
        ...state,
        warehouseList: action.payload,
        isFetchingWarehouseList: false,
        dataNotAddedWarehouse: false,
      };
    case "ERROR_GET_ALL_WAREHOUSE":
      return {
        ...state,
        isFetchingWarehouseList: false,
      };


    case "DELETE_REQUEST_WAREHOUSE":
      return { ...state };
    case "DELETE_SUCCESS_WAREHOUSE":
      return {
        ...state,
        warehouseList: state.warehouseList.filter(item => item.warehouseId !== action.warehouseId)
      };
    case "DELETE_ERROR_WAREHOUSE":
      return { ...state };


    case "UPDATE_REQUEST_WAREHOUSE":
      return { ...state };
    case "UPDATE_SUCCESS_WAREHOUSE":
      return {
        ...state,
        warehouse: state.warehouse.map(x => action.payload.find(({ warehouseId }) => warehouseId === x.warehouseId) || x)
      };
    case "UPDATE_ERROR_WAREHOUSE":
      return { ...state };


    case "REQUEST_GET_WAREHOUSE_WITH_RESID":
      return {
        ...state,
        isFetchingWarehouseListWithResId: true,
      };
    case "SUCCESS_GET_WAREHOUSE_WITH_RESID":
      return {
        ...state,
        warehouseListWithResId: action.payload,
        isFetchingWarehouseListWithResId: false,
      };
    case "ERROR_GET_WAREHOUSE_WITH_RESID":
      return {
        ...state,
        isFetchingWarehouseListWithResId: false,
      };

    default:
      return state;
  }
};
export default WarehouseReducer;