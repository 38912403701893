import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateServiceStatusApi, DeleteCashServiceApi, CashServiceEnglishPDF, GetCashServiceWithIdApi, CashServiceArabicPDF } from "./cashServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
// import _ from 'lodash'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import moment from "moment";
import Button from '@mui/material/Button';
const tableRef = React.createRef()


const CompleteServiceList = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openPDF, setOpenPDF] = React.useState(false);
    const handleOpenPDF = () => { setOpenPDF(true) };
    const handleClosePDF = () => setOpenPDF(false);
    const [seletcedDataPDF, setSelectedDataPDF] = React.useState();
    const stylePDF = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    function PrintEnglishPDF() {
        props.CashServiceEnglishPDF(seletcedDataPDF)
        handleClosePDF()
    }
    function PrintArabicPDF() {
        props.CashServiceArabicPDF(seletcedDataPDF)
        handleClosePDF()
    }

    useEffect(() => {
        props.setHeading("Bill Payment");
    });

    return (
        <>
            {props.isFetchingCashService ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Bill Payment List"
                            columns={[
                                { title: 'Invoice No', field: 'seqNumber' },
                                { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                                { title: "Date", field: "date", render: rowData => moment(rowData.date).format("DD-MM-YYYY") },
                                { title: "Status", field: "status" },
                                { title: "Vehicle Number", field: "vehicleNumber" },
                                { title: "Vehicle Made", field: "vehicleMade" },
                                { title: "Vehicle Model", field: "vehicleModel" },
                                { title: "Customer Name", field: "customerName" },
                                { title: "Customer Number", field: "customerNumber" },
                                { title: "Customer Email", field: "customerEmail" },
                                { title: "Employee Name", field: "employeeDetails.name" },
                                { title: "Advance Amount", field: "advance" },
                                { title: "Total Expense", field: "totalExpense" },
                                { title: "VAT", field: "vatTotal" },
                                { title: "Final Total", field: "finalTotal" },
                                { title: "Remarks", field: "remarks" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/cashService?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data.sort((a, b) => b.seqNumber - a.seqNumber),
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteCashServiceApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Refresh Data',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                },
                                (rowData) => ({
                                    disabled: rowData.status === 'Completed' ? false : true,
                                    icon: () => <TaskAltIcon />,
                                    tooltip: "Bill Paid",
                                    onClick: (event, rowData) => {
                                        const status = 'Bill Paid'
                                        props.UpdateServiceStatusApi(status, rowData._id);
                                        tableRef.current && tableRef.current.onQueryChange();
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Service",
                                    onClick: (event, rowData) => {
                                        // props.CashServiceEnglishPDF(rowData)
                                        setSelectedDataPDF(rowData)
                                        handleOpenPDF()
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <VisibilityIcon />,
                                    tooltip: "Preview Service",
                                    onClick: (event, rowData) => {
                                        props.GetCashServiceWithIdApi(rowData._id)
                                        navigate("/mainService/Cash/DetailViewCashService", { state: { from: location.pathname } })
                                    },
                                }),
                                (rowData) => ({
                                    disabled: rowData.status === 'Bill Paid' ? true : false,
                                    icon: () => <BorderColorIcon />,
                                    tooltip: "Update Service",
                                    onClick: async (event, rowData) => {
                                        if (rowData.status === 'Completed') {
                                            navigate("/mainService/Cash/AddExpense");
                                        } else if (rowData.status === 'Work In Process') {
                                            props.GetCashServiceWithIdApi(rowData._id)
                                            navigate("/mainService/Cash/EditExpense")
                                        }
                                    },
                                }),
                            ]}
                        />
                    </div>

                    <Modal
                        open={openPDF}
                        onClose={handleClosePDF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePDF}>
                            <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Please Select Language </Col>
                            <Row>
                                <Col>
                                    <Button variant="contained" size="large" onClick={PrintEnglishPDF}> English </Button>
                                </Col>
                                <Col>
                                    <Button variant="contained" size="large" onClick={PrintArabicPDF}> Arabic </Button>
                                </Col>
                            </Row>
                        </Box>
                    </Modal>

                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteCashServiceApi: (productId) => dispatch(DeleteCashServiceApi(productId)),
        CashServiceEnglishPDF: (data) => dispatch(CashServiceEnglishPDF(data)),
        CashServiceArabicPDF: (data) => dispatch(CashServiceArabicPDF(data)),
        GetCashServiceWithIdApi: (id) => dispatch(GetCashServiceWithIdApi(id)),
        UpdateServiceStatusApi: (newData, oldData) => dispatch(UpdateServiceStatusApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataListWithId: state.CashServiceReducer.cashServiceDataListWithId,
    isFetchingCashServiceWithId: state.CashServiceReducer.isFetchingCashServiceWithId,
    cashServiceDataList: state.CashServiceReducer.cashServiceDataList,
    isFetchingCashService: state.CashServiceReducer.isFetchingCashService,
});


export default connect(mapStateToProps, mapDispatchToProps)(CompleteServiceList);