import API from "../../../global/api";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const SET_MODULE_KEY = "SET_MODULE_KEY";
export const SET_HEADING_KEY = "SET_HEADING_KEY";


export const loginUserRequest = () => {
  return {
    type: "LOGIN_USER_REQUEST",
  };
};
export const loginUserSuccess = (data) => {
  return {
    type: "LOGIN_USER_SUCCESS",
    payload: data,
  };
};
export const loginUserError = (msg) => {
  return {
    type: "LOGIN_USER_ERROR",
    payload: msg
  };
};
export const getLogin = (username, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(loginUserRequest())
    API.post(`/users/login`, username).then((res) => {
      let data1 = res.data;
      dispatch(loginUserSuccess(data1));

      window.localStorage.setItem("res", res.message);
      window.localStorage.setItem("token", data1.token);
      window.localStorage.setItem("userId", data1._id);
      window.localStorage.setItem("isAuthenticatedUser", "true")
      window.localStorage.setItem("isUser", "true");
      window.localStorage.setItem("isAdmin", false);
      window.localStorage.setItem("isAuthenticated", "true");
      window.localStorage.setItem("permissions", JSON.stringify(data1.permission));
      window.location.replace('/home');

    }).catch((error) => {
      dispatch(loginUserError(error.response.data.message))
      handleDangerVisible(error.response.data.message)
    });
  };
};

export const setModuleKey = (data) => {
  return {
    type: "SET_MODULE_KEY",
    payload: data,
  };
};

export const setHeading = (data) => {
  return {
    type: "SET_HEADING_KEY",
    payload: data,
  };
};

export const changeIsWrong = () => {
  return {
    type: "CHANGE_IS_WRONG",
  };
};