import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { SearchStoreLedgerApi } from "./StoreLedgerAction";
import { getProductData } from "../../MasterControl/Product/productAction";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import '../../../../../il8n';


const StoreLedger = (props) => {
    const { t, i18n } = useTranslation();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [item, setItem] = useState("");


    const handleChangeProductList = (selectedOption) => {
        setItem(selectedOption.value);
    };
    const getProductDataList = props.productDataList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });

    useEffect(() => {
        props.getProductData();
        props.setHeading("Store Ledger");
    }, []);

    function searchData() {
        props.SearchStoreLedgerApi({
            startDate: !isEmpty(startDate) ? startDate : "",
            endDate: !isEmpty(endDate) ? endDate : "",
            productId: item,
        },
            startDate,
            endDate
        );
        return <Navigate to="/store/storeLedgerData" />;
    }

    return (
        <>
            {props.isFetchingProduct ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>{t('StartDate')}</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>{t('EndDate')}</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>{t('SelectProduct')} *</b></Form.Label>
                                    <Select
                                        placeholder="Select Product"
                                        onChange={handleChangeProductList}
                                        options={getProductDataList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div style={{ marginTop: '40px', textAlign: 'center' }}>
                            {isEmpty(item) ? (
                                <Button variant="contained" endIcon={<SearchIcon />} size="large" disabled> SEARCH LEDGER </Button>
                            ) : (
                                <Link to={{ pathname: "/store/storeLedgerData", state: { start: startDate, end: endDate } }}>
                                    <Button variant="contained" endIcon={<SearchIcon />} size="large" onClick={(e) => searchData()}> SEARCH LEDGER </Button>
                                </Link>
                            )}
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProductData: () => dispatch(getProductData()),
        SearchStoreLedgerApi: (data, startDate, endDate) => dispatch(SearchStoreLedgerApi(data, startDate, endDate)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    searchInventoryLedgerList: state.StoreLegderReducer.searchInventoryLedgerList,
    isFetchingSearchInventoryLedger: state.StoreLegderReducer.isFetchingSearchInventoryLedger,
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreLedger);
