import React, { useState } from "react";
import { useEffect } from "react";
import { Container, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { AddEmployeeApi } from "./employeeAction";
import { GetCompanyApi } from "../../setting/Company/companyAction";
import { getWarehouseWithResApi } from "../../setting/BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import _ from "lodash";
import Select from "react-select";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import '../../../../../il8n';


const AddEmployee = (props) => {
    const { t, i18n } = useTranslation();
    const [companyId, setCompanyId] = useState();
    const [companyName, setCompanyName] = useState();
    const [warehouseId, setWarehouseId] = useState();
    const [warehouseName, setWarehouseName] = useState();
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function SaveEmployee() {
        props.AddEmployeeApi({
            name: name,
            phone: phone,
            email: email,
            address: address,
            companyId: companyId,
            companyName: companyName,
            businessId: warehouseId,
            warehouseName: warehouseName,
        },
            handleVisible,
            handleDangerVisible
        );
        setCompanyId("")
        setWarehouseId("")
        setName("")
        setPhone("")
        setEmail("")
        setAddress("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeCompany = (selectedOption) => {
        setCompanyId(selectedOption.value);
        setCompanyName(selectedOption.label);
        props.getWarehouseWithResApi(selectedOption.value);
    };
    const getCompanyList = !_.isEmpty(props.companyDataList) && props.companyDataList.map((x) => {
        let data = { value: x._id, label: x.companyName };
        return data;
    });

    const handleChangeWarehouse = (selectedOption) => {
        setWarehouseId(selectedOption.value);
        setWarehouseName(selectedOption.label);
    };
    const getWarehouseList = !_.isEmpty(props.warehouseListWithResId) && props.warehouseListWithResId.data.map((x) => {
        let data = { value: x._id, label: x.businessName };
        return data;
    });


    useEffect(() => {
        props.setHeading("Add Employee")
        props.GetCompanyApi()
    }, []);

    return (
        <>
            {props.isFetchingCompany || props.isAddingEmployee ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('SelectCompany')} *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(props.companyDataList)}
                                        placeholder="Select Company"
                                        onChange={handleChangeCompany}
                                        options={getCompanyList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('SelectBusinessLocation')} *</b></Form.Label>
                                    <Select
                                        placeholder="Select Business Location"
                                        isDisabled={ _.isEmpty(companyId)}
                                        onChange={handleChangeWarehouse}
                                        options={getWarehouseList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('Name')} *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('Phone')} *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Phone"
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('Email')}</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Email"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>{t('Address')} </b></Form.Label>
                                    <Form.Control
                                        type="test"
                                        placeholder="Address"
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            {_.isEmpty(companyId) || _.isEmpty(warehouseId) || _.isEmpty(name) || _.isEmpty(phone) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveEmployee}> SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>)}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddEmployeeApi: (data, handleVisible, handleDangerVisible) => dispatch(AddEmployeeApi(data, handleVisible, handleDangerVisible)),
        GetCompanyApi: () => dispatch(GetCompanyApi()),
        setHeading: (data) => dispatch(setHeading(data)),
        getWarehouseWithResApi: (id) => dispatch(getWarehouseWithResApi(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    addEmployee: state.EmployeeReducer.addEmployee,
    isAddingEmployee: state.EmployeeReducer.isAddingEmployee,
    companyDataList: state.CompanyReducer.companyDataList,
    isFetchingCompany: state.CompanyReducer.isFetchingCompany,
    warehouseListWithResId: state.WarehouseReducer.warehouseListWithResId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
