import API from "../../../../../global/api";


export const requestAddWarehouse = () => {
    return {
        type: "REQUEST_ADD_WAREHOUSE",
    };
};
export const successAddWarehouse = (data) => {
    return {
        type: "SUCCESS_ADD_WAREHOUSE",
        payload: data,
    };
};
export const errorAddWarehouse = () => {
    return {
        type: "ERROR_ADD_WAREHOUSE",
    };
};
export const addWarehouseApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddWarehouse());
        API.post(`/businesses`, data).then((res) => {
            dispatch(successAddWarehouse(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddWarehouse());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetWarehouse = () => {
    return {
        type: "REQUEST_GET_ALL_WAREHOUSE",
    };
};
export const successGetWarehouse = (data) => {
    return {
        type: "SUCCESS_GET_ALL_WAREHOUSE",
        payload: data,
    };
};
export const errorGetWarehouse = () => {
    return {
        type: "ERROR_GET_ALL_WAREHOUSE",
    };
};
export const getWarehouseApi = () => {
    return (dispatch) => {
        dispatch(requestGetWarehouse());
        API.get(`/businesses`).then((res) => {
            let data1 = res.data;
            dispatch(successGetWarehouse(data1));
        }).catch((error) => {
            dispatch(errorGetWarehouse());
        })
    };
};


export const DeleteRequestWarehouse = () => {
    return {
        type: "DELETE_REQUEST_WAREHOUSE",
    };
};
export const DeleteSuccessWarehouse = (user_id) => {
    return {
        type: "DELETE_SUCCESS_WAREHOUSE",
        user_id: user_id,
    };
};
export const DeleteErrorWarehouse = () => {
    return {
        type: "DELETE_ERROR_WAREHOUSE",
    };
};
export const deleteWarehouseApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestWarehouse());
        API.delete(`/businesses/${id}`).then(res => {
            dispatch(getWarehouseApi());
            dispatch(DeleteSuccessWarehouse(id))
        }).catch((error) =>
            dispatch(DeleteErrorWarehouse()));
    }
};


export const UpdateRequestWarehouse = () => {
    return {
        type: "UPDATE_REQUEST_WAREHOUSE",
    };
};
export const UpdateSuccessWarehouse = (data) => {
    return {
        type: "UPDATE_SUCCESS_WAREHOUSE",
        payload: data,
    };
};
export const UpdateErrorWarehouse = () => {
    return {
        type: "UPDATE_ERROR_WAREHOUSE",
    };
};
export const updateWarehouseApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestWarehouse());
        API.put(`/businesses/${oldData._id}`, {
            businessName: data.businessName,
            location: data.location,
            city: data.city,
            zipCode: data.zipCode,
            state: data.state,
            country: data.country,
            phone: data.phone,
            description: data.description,
            manager: data.manager,
        }, {
        }).then(res => {
            dispatch(getWarehouseApi());
            dispatch(UpdateSuccessWarehouse([data]))
        }).catch((error) =>
            dispatch(UpdateErrorWarehouse()));
    }
};


export const requestGetWarehouseWithCompanyId = () => {
    return {
        type: "REQUEST_GET_WAREHOUSE_WITH_RESID",
    };
};
export const successGetWarehouseWithCompanyId = (data) => {
    return {
        type: "SUCCESS_GET_WAREHOUSE_WITH_RESID",
        payload: data,
    };
};
export const errorGetWarehouseWithCompanyId = () => {
    return {
        type: "ERROR_GET_WAREHOUSE_WITH_RESID",
    };
}
export const getWarehouseWithResApi = (id) => {
    return (dispatch) => {
        dispatch(requestGetWarehouseWithCompanyId());
        API.get(`/businesses/get_business_with_companyId/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetWarehouseWithCompanyId(getData));
        }).catch((error) => {
            dispatch(errorGetWarehouseWithCompanyId());
        })
    };
};