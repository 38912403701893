import API from "../../../../../global/api";


export const requestSearchProductLedger = () => {
    return {
        type: "REQUEST_SEARCH_PRODUCT",
    };
};
export const successSearchProductLedger = (data) => {
    return {
        type: "SUCCESS_SEARCH_PRODUCT",
        payload: data,
    };
};
export const errorSearchProductLedger = () => {
    return {
        type: "ERROR_SEARCH_PRODUCT",
    };
};
export const SearchStoreLedgerApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestSearchProductLedger());
        API.post(`/products/search_ledger`, data).then((res) => {
            let getData = res.data;
            dispatch(successSearchProductLedger(getData));
        }).catch((error) => {
            dispatch(errorSearchProductLedger());
        });
    };
};