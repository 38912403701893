import { Route, Routes } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import * as React from 'react';
import Login from "../views/user_views/accounts/Login";
import UserMainPage from "../views/user_views/accounts/UserMainPage";
import Dashboard from "../views/user_views/drawer/ResponsiveDrawer";
import AddCompany from "../views/user_views/forms/setting/Company/addCompany";
import ListCompany from "../views/user_views/forms/setting/Company/companyList";
import AddBusinessLocation from "../views/user_views/forms/setting/BusinessLocation/AddBusinessLocation";
import ListBusinessLocation from "../views/user_views/forms/setting/BusinessLocation/BusinessLocationList";
import ViewUser from "../views/user_views/forms/setting/AddUser/ViewUser";
import AddUser from "../views/user_views/forms/setting/AddUser/AddUser";
import BackUp from "../views/user_views/forms/setting/BackUp/BackUp";
import AddService from "../views/user_views/forms/MasterControl/Service/addService";
import ViewService from "../views/user_views/forms/MasterControl/Service/serviceList";
import AddProduct from "../views/user_views/forms/MasterControl/Product/addProduct";
import ListProduct from "../views/user_views/forms/MasterControl/Product/productList";
import AddInspector from "../views/user_views/forms/MasterControl/Inspector/inspectorAdd";
import ListInspector from "../views/user_views/forms/MasterControl/Inspector/inspectorList";
import AddInsuranceCompany from "../views/user_views/forms/MasterControl/InsuranceCompany/addInsuranceCompany";
import ListInsuranceCompany from "../views/user_views/forms/MasterControl/InsuranceCompany/insuranceCompanyList";
import AddPurchaseOrder from "../views/user_views/forms/Store/PurchaseOrder/AddPurchaseOrder";
import ViewPurchaseOrder from "../views/user_views/forms/Store/PurchaseOrder/ViewPurchaseOrder";
import StoreLedger from "../views/user_views/forms/Store/StoreLedger/StoreLedger";
import StoreLedgerDataTable from "../views/user_views/forms/Store/StoreLedger/StoreLedgerDataTable";
import UserPermissions from "../views/user_views/forms/MasterControl/Permissions/UserPermissions";
import AddEmployee from "../views/user_views/forms/MasterControl/Employee/addEmployee";
import EmployeeView from "../views/user_views/forms/MasterControl/Employee/employeeView";


// CASH SERVICE ROUTES
import AddCashService from "../views/user_views/forms/MainServices/CashService/addCashService";
import AddCashExpense from "../views/user_views/forms/MainServices/CashService/addExpense";
import CompleteCashList from "../views/user_views/forms/MainServices/CashService/completeList";
import CashBillPaymentList from "../views/user_views/forms/MainServices/CashService/billPaymentList";
import AddDeliveryPass from "../views/user_views/forms/MainServices/CashService/addDeliveryPass";
import CashServiceReport from "../views/user_views/forms/MainServices/CashService/cashServiceReport";
import CashServiceReportData from "../views/user_views/forms/MainServices/CashService/cashServiceReportData";
import DeliveryPassReport from "../views/user_views/forms/MainServices/CashService/deliveryPassReport";
import EditCashExpense from "../views/user_views/forms/MainServices/CashService/editExpense";
import DetailViewCashService from "../views/user_views/forms/MainServices/CashService/detailViewCashService";

// INSURANCE SERVICE ROUTES
import AddInsuranceService from "../views/user_views/forms/MainServices/InsuranceService/addInsuranceService";
import AddInsuranceGatePass from "../views/user_views/forms/MainServices/InsuranceService/addGatepass";
import AddInsuranceExpense from "../views/user_views/forms/MainServices/InsuranceService/addExpense";
import InspectionList from "../views/user_views/forms/MainServices/InsuranceService/inspectionList";
import CompleteInsuranceList from "../views/user_views/forms/MainServices/InsuranceService/completeList";
import InsuranceBillPaymentList from "../views/user_views/forms/MainServices/InsuranceService/billPaymentList";
import AddInsuranceDeliveryPass from "../views/user_views/forms/MainServices/InsuranceService/addDeliveryPass";
import IncServiceReport from "../views/user_views/forms/MainServices/InsuranceService/IncServiceReport";
import IncServiceReportData from "../views/user_views/forms/MainServices/InsuranceService/IncServiceReportData";
import DeliveryPassReportInc from "../views/user_views/forms/MainServices/InsuranceService/deliveryPassReport";
import EditIncExpense from "../views/user_views/forms/MainServices/InsuranceService/editExpense";
import IncPDFReport from "../views/user_views/forms/MainServices/InsuranceService/IncPDFReport";
import DetailViewIncService from "../views/user_views/forms/MainServices/InsuranceService/detailViewIncService";




export default function Routing() {
  const location = useLocation();

  React.useEffect(() => { }, [location]);

  return (
    <Routes>

      {/* <Route path="/" component={Login} /> */}
      <Route path="/" element={<Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/home" element={<UserMainPage />}></Route>


      {/*MAIN SERVICE ROUTES */}
      {true && (
        <Route path="/mainService" element={<Dashboard />}>
          <Route path="/mainService/Cash/AddCashService" element={<AddCashService />} />
          <Route path="/mainService/Cash/AddExpense" element={<AddCashExpense />} />
          <Route path="/mainService/Cash/CompleteList" element={<CompleteCashList />} />
          <Route path="/mainService/Cash/BillPaymentList" element={<CashBillPaymentList />} />
          <Route path="/mainService/Cash/AddDeliveryPass" element={<AddDeliveryPass />} />
          <Route path="/mainService/Cash/Report" element={<CashServiceReport />} />
          <Route path="/mainService/Cash/CashServiceReportData" element={<CashServiceReportData />} />
          <Route path="/mainService/Cash/DeliveryPassReport" element={<DeliveryPassReport />} />
          <Route path="/mainService/Cash/EditExpense" element={<EditCashExpense />} />
          <Route path="/mainService/Cash/DetailViewCashService" element={<DetailViewCashService />} />


          <Route path="/mainService/Insurance/AddInsuranceService" element={<AddInsuranceService />} />
          <Route path="/mainService/Insurance/AddGatePass" element={<AddInsuranceGatePass />} />
          <Route path="/mainService/Insurance/AddExpense" element={<AddInsuranceExpense />} />
          <Route path="/mainService/Insurance/InspectionList" element={<InspectionList />} />
          <Route path="/mainService/Insurance/CompleteList" element={<CompleteInsuranceList />} />
          <Route path="/mainService/Insurance/BillPaymentList" element={<InsuranceBillPaymentList />} />
          <Route path="/mainService/Insurance/AddDeliveryPass" element={<AddInsuranceDeliveryPass />} />
          <Route path="/mainService/Insurance/Report" element={<IncServiceReport />} />
          <Route path="/mainService/Insurance/IncServiceReportData" element={<IncServiceReportData />} />
          <Route path="/mainService/Insurance/DeliveryPassReport" element={<DeliveryPassReportInc />} />
          <Route path="/mainService/Insurance/EditExpense" element={<EditIncExpense />} />
          <Route path="/mainService/Insurance/IncPDFReport" element={<IncPDFReport />} />
          <Route path="/mainService/Insurance/DetailViewIncService" element={<DetailViewIncService />} />

        </Route>
      )}


      {/* MASTER CONTROL ROUTES */}
      {true && (
        <Route path="/masterControl" element={<Dashboard />}>
          <Route path="/masterControl/service/AddService" element={<AddService />} />
          <Route path="/masterControl/service/ListService" element={<ViewService />} />
          <Route path="/masterControl/insuranceCompany/AddInsuranceCompany" element={<AddInsuranceCompany />} />
          <Route path="/masterControl/insuranceCompany/ListInsuranceCompany" element={<ListInsuranceCompany />} />
          <Route path="/masterControl/inspector/AddInspector" element={<AddInspector />} />
          <Route path="/masterControl/inspector/ListInspector" element={<ListInspector />} />
          <Route path="/masterControl/employee/AddEmployee" element={<AddEmployee />} />
          <Route path="/masterControl/employee/EmployeeView" element={<EmployeeView />} />

        </Route>
      )}


      {/* SETTING ROUTES */}
      {true && (
        <Route path="/setting" element={<Dashboard />}>
          <Route path="/setting/BackUp" element={<BackUp />} />
          <Route path="/setting/Company/AddCompany" element={<AddCompany />} />
          <Route path="/setting/Company/ListCompany" element={<ListCompany />} />
          <Route path="/setting/BusinessLocation/AddBusinessLocation" element={<AddBusinessLocation />} />
          <Route path="/setting/BusinessLocation/ListBusinessLocation" element={<ListBusinessLocation />} />
          <Route path="/setting/User/AddUser" element={<AddUser />} />
          <Route path="/setting/user/ListUser" element={<ViewUser />} />
          <Route path="/setting/permission/AddPermission" element={<UserPermissions />} />

        </Route>
      )}


      {/* STORE ROUTES */}
      {true && (
        <Route path="/store" element={<Dashboard />}>
          <Route path="/store/product/AddProduct" element={<AddProduct />} />
          <Route path="/store/product/ListProduct" element={<ListProduct />} />
          <Route path="/store/PurchaseOrder/AddPurchaseOrder" element={<AddPurchaseOrder />} />
          <Route path="/store/PurchaseOrder/ViewPurchaseOrder" element={<ViewPurchaseOrder />} />
          <Route path="/store/storeLedger" element={<StoreLedger />} />
          <Route path="/store/storeLedgerData" element={<StoreLedgerDataTable />} />

        </Route>
      )}


    </Routes>)
}