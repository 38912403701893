import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { CashServiceEnglishPDF, GetCashServiceWithIdApi } from "./cashServiceAction";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import _ from 'lodash'
import moment from "moment";


const CashServiceReportData = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {props.isFetchingCashServiceWithStatus ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                            <Link to="/mainService/Cash/Report">
                                <Button variant="contained" endIcon={<ArrowBackIcon />} size="large" > BACK TO SEARCH </Button>
                            </Link>
                        </div>

                        <div className="main">
                            <MaterialTable
                                title="Cash Service Report"
                                columns={[
                                    { title: 'Invoice No', field: 'seqNumber' },
                                    { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                                    { title: "Date", field: "date", render: rowData => moment(rowData.date).format("DD-MM-YYYY") },
                                    { title: "Status", field: "status" },
                                    { title: "Vehicle Number", field: "vehicleNumber" },
                                    { title: "Vehicle Made", field: "vehicleMade" },
                                    { title: "Vehicle Model", field: "vehicleModel" },
                                    { title: "Customer Name", field: "customerName" },
                                    { title: "Customer Number", field: "customerNumber" },
                                    { title: "Customer Email", field: "customerEmail" },
                                    { title: "Employee Name", field: "employeeName" },
                                    { title: "Advance Amount", field: "advance" },
                                    { title: "Total Expense", field: "totalExpense" },
                                    { title: "VAT", field: "vatTotal" },
                                    { title: "Final Total", field: "finalTotal" },
                                    { title: "Remarks", field: "remarks" },
                                ]}
                                data={props.cashServiceDataListWithStatus.sort((a, b) => b.seqNumber - a.seqNumber)}
                                options={{
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    paging: true,
                                    pageSize: 50,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [50, 100, 150, 200],
                                    headerStyle: {
                                        position: "sticky",
                                        top: 0,
                                        color: "#00BBBB",
                                        fontWeight: "550",
                                        onRowAdd: "none",
                                    },
                                }}
                                actions={[
                                    (rowData) => ({
                                        icon: () => <PrintIcon />,
                                        tooltip: "Print Service",
                                        onClick: (event, rowData) => {
                                            props.CashServiceEnglishPDF(rowData)
                                        },
                                    }),
                                    (rowData) => ({
                                        icon: () => <VisibilityIcon />,
                                        tooltip: "Preview Service",
                                        onClick: (event, rowData) => {
                                            props.GetCashServiceWithIdApi(rowData._id)
                                            navigate("/mainService/Cash/DetailViewCashService", { state: { from: location.pathname } })
                                        },
                                    }),
                                ]}
                            />
                        </div>
                    </div>

                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        CashServiceEnglishPDF: (data) => dispatch(CashServiceEnglishPDF(data)),
        GetCashServiceWithIdApi: (id) => dispatch(GetCashServiceWithIdApi(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataListWithId: state.CashServiceReducer.cashServiceDataListWithId,
    isFetchingCashServiceWithId: state.CashServiceReducer.isFetchingCashServiceWithId,
    cashServiceDataListWithStatus: state.CashServiceReducer.cashServiceDataListWithStatus,
    isFetchingCashServiceWithStatus: state.CashServiceReducer.isFetchingCashServiceWithStatus,
});

export default connect(mapStateToProps, mapDispatchToProps)(CashServiceReportData);