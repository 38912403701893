import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { socket } from '../../../socket';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Done from '@mui/icons-material/Done';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import '../../../il8n'


export default function IconMenu() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const changeLanguage = (lng) => {
    setAnchorEl(null);
    i18n.changeLanguage(lng);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    window.localStorage.clear();
    socket.disconnect();
    window.location.replace('/login');
  }
  // const handleHome = () => {
  //   window.location.replace('/home');
  // }

  return (
    <React.Fragment>
      <Box style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <div>
            <Link to="/home">
              <Button variant="contained" endIcon={<HomeIcon />} size="small" style={{ marginRight: '10px', padding: '7px' }}> Home </Button>
            </Link>
            <Link onClick={() => handleLogout()}>
              <Button variant="contained" endIcon={<Logout />} size="small" style={{ padding: '7px' }}> LogOut </Button>
            </Link>

            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
            // disabled
            // style={{ backgroundColor: '#1976d2', color: 'white', padding: '10px' }}
            // aria-controls={open ? 'account-menu' : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
            >
              <GTranslateIcon sx={{ width: 25, height: 25 }}></GTranslateIcon>
            </IconButton>
          </div>
        </Tooltip>
      </Box>


      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => changeLanguage('en')} style={{ marginLeft: 5 }}> English
          {i18n.language === 'en' &&
            <ListItemIcon style={{ marginLeft: 5 }}>
              <Done fontSize="small" />
            </ListItemIcon>}
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('ar')} style={{ marginLeft: 5 }}> Arabic
          {i18n.language === 'ar' &&
            <ListItemIcon style={{ marginLeft: 5 }}>
              <Done fontSize="small" />
            </ListItemIcon>}
        </MenuItem>
      </Menu>

    </React.Fragment>
  );
}