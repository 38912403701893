import React, { useEffect } from "react";
import { Navbar, Container, } from "react-bootstrap";
import { connect } from "react-redux";
// import _ from "lodash";
import { Link } from "react-router-dom";
import { setHeading, setModuleKey } from "./account.actions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconMenu from "../drawer/IconMenu";
import CardMedia from "@mui/material/CardMedia";
import InventoryPic from "../../../../src/Images/Inventory.png";
import MasterControlPic from "../../../Images/MasterControl.png"
import StorePic from "../../../Images/store.png"
import Background from "../../../../src/Images/Background.png"


const UserMainPage = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  useEffect(() => { }, []);
  return (
    <>
      <div >
        <Navbar style={{ backgroundColor: '#81abd3' }} >
          <Container>
            <Navbar.Brand href="" style={{ color: 'white' }}><b>Home Page</b></Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>

      <div style={{ width: "100%", backgroundImage: `url(${Background})`, height: '1000px', overflow: 'auto', backgroundSize: 'cover' }}>
        <Box sx={{ flexGrow: 1, mt: 8, mx: "auto", ml: 8 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 5, sm: 6, md: 6 }} >


            {permissions.some(p => p['permissionsName'] === "Garage") &&
              <Link to="/mainService">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("mainService");
                    window.localStorage.setItem("module_key", "mainService");
                    props.setHeading("Services")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="mainService"
                    height="240"
                    width="240"
                    image={InventoryPic}
                  />
                </Card>
              </Link>
            }

            {permissions.some(p => p['permissionsName'] === "Master Control") &&
              <Link to="/masterControl">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("masterControl");
                    window.localStorage.setItem("module_key", "masterControl");
                    props.setHeading("Master Control")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="masterControl"
                    height="240"
                    width="240"
                    image={MasterControlPic}
                  />
                </Card>
              </Link>
            }


            {permissions.some(p => p['permissionsName'] === "Store") &&
              <Link to="/store">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("store");
                    window.localStorage.setItem("module_key", "store");
                    props.setHeading("Store")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="store"
                    height="240"
                    width="240"
                    image={StorePic}
                  />
                </Card>
              </Link>
            }


          </Grid>
        </Box>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setModuleKey: (data) => dispatch(setModuleKey(data)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserMainPage);