import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { baseURL, headers } from "../../../../../global/api";
import { DeleteInspectorApi, UpdateInspectorApi } from "./inspectorAction";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";


const InspectorList = (props) => {
    // const permissions = JSON.parse(window.localStorage.getItem("permissions"))
    // const Checkpermissions = (permissionsType, permissionsName) => {
    //     return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
    // }
    useEffect(() => {
        props.setHeading("Inspector List");
    }, []);
    return (
        <>
            {props.isFetchingInspector ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Inspector List"
                            columns={[
                                { title: 'Insurance Company', field: 'insuranceCompanyDetails.companyName', editable: 'never' },
                                { title: 'Inspector Name', field: 'inspectorName' },
                                { title: 'Email', field: 'email' },
                                { title: 'Phone', field: 'phone' },
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/inspector?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateInspectorApi(newData, oldData)
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteInspectorApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DeleteInspectorApi: (companyId) => dispatch(DeleteInspectorApi(companyId)),
        UpdateInspectorApi: (newData, oldData) => dispatch(UpdateInspectorApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    inspectorDataList: state.InspectorReducer.inspectorDataList,
    isFetchingInspector: state.InspectorReducer.isFetchingInspector,
});

export default connect(mapStateToProps, mapDispatchToProps)(InspectorList);