import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { GetCashServiceWithStatusApi } from "./cashServiceAction";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";
import _ from "lodash";
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import '../../../../../il8n';


const CashServiceReport = (props) => {
    const { t, i18n } = useTranslation();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [status, setStatusValue] = useState("");

    const handleChangeStatus = (selectedOption) => {
        setStatusValue(selectedOption.map(obj => obj.value));
    };
    const getStatusList = [
        { value: "Work In Process", label: "Work In Process" },
        { value: "Completed", label: "Completed" },
        { value: "Bill Paid", label: "Bill Paid" },
    ];

    useEffect(() => {
        props.setHeading("Cash Service Report");
    }, []);

    function searchData() {
        props.GetCashServiceWithStatusApi({
            startDate: !_.isEmpty(startDate) ? startDate : "",
            endDate: !_.isEmpty(endDate) ? endDate : "",
            status: status,
        },
            startDate,
            endDate
        );
        return <Navigate to="/mainService/Cash/CashServiceReportData" />;
    }

    return (
        <>
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('StartDate')}</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('EndDate')}</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('SelectStatus')} *</b></Form.Label>
                                <Select
                                    placeholder="Select Status"
                                    onChange={handleChangeStatus}
                                    options={getStatusList}
                                    isMulti
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '40px', textAlign: 'center' }}>
                        {_.isEmpty(status) ? (
                            <Button variant="contained" endIcon={<SearchIcon />} size="large" disabled> SEARCH </Button>
                        ) : (
                            <Link to={{ pathname: "/mainService/Cash/CashServiceReportData", state: { start: startDate, end: endDate } }}>
                                <Button variant="contained" endIcon={<SearchIcon />} size="large" onClick={(e) => searchData()}> SEARCH </Button>
                            </Link>
                        )}
                    </div>

                </div>
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetCashServiceWithStatusApi: (data, startDate, endDate) => dispatch(GetCashServiceWithStatusApi(data, startDate, endDate)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
}
const mapStateToProps = (state, ownProps) => ({
    cashServiceDataListWithStatus: state.CashServiceReducer.cashServiceDataListWithStatus,
    isFetchingCashServiceWithStatus: state.CashServiceReducer.isFetchingCashServiceWithStatus,
})

export default connect(mapStateToProps, mapDispatchToProps)(CashServiceReport);