import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { NavLink } from "react-router-dom";
import ListAltIcon from '@mui/icons-material/ListAlt';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BusinessIcon from '@mui/icons-material/Business';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import ManIcon from '@mui/icons-material/Man';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SecurityIcon from '@mui/icons-material/Security';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useTranslation } from "react-i18next";
import '../../../il8n';



export default function Menu(props) {
  const [openCompany, setOpenCompany] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [businesslocation, setOpenBusinesslocation] = React.useState(false);
  const [service, setOpenService] = React.useState(false);
  const [product, setOpenProduct] = React.useState(false);
  const [insuranceCompany, setOpenInsuranceCompany] = React.useState(false);
  const [inspector, setOpenInspector] = React.useState(false);
  const [cashService, setOpenCashService] = React.useState(false);
  const [insuranceService, setOpenInsuranceService] = React.useState(false);
  const [purchaseOrder, setOpenPurchaseOrder] = React.useState(false);
  const [employee, setOpenEmployee] = React.useState(false);
  const [incReport, setIncReport] = React.useState(false);
  const { t, i18n } = useTranslation();


  const Checkpermissions = (permissionsType, permissionsName) => {
    return props.permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
  }


  // { MAIN SERVICES }
  let mainServiceList = <List>
    {Checkpermissions("permissionsCatName", "Cash Service") &&
      <ListItemButton onClick={() => setOpenCashService(!cashService)}>
        <ListItemIcon>
          <MonetizationOnIcon />
        </ListItemIcon>
        <ListItemText primary={t('CashService')} />
        {cashService ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    }
    <Collapse in={cashService} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddCashService" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Add Cash Service") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddCashService')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddExpense" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Add Cash Expense") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('OurCost')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/CompleteList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Cash Complete Service") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('CompleteService')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/BillPaymentList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Cash Bill Payment") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('BillPayment')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/AddDeliveryPass" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Cash Delivery Pass") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('DeliverPass')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/DeliveryPassReport" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Cash Delivery Pass Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary={t('DeliverPassReport')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Cash/Report" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Cash Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary={t('CashServiceReport')} />
            </ListItemButton>}
        </NavLink>
      </List>

    </Collapse>

    {Checkpermissions("permissionsCatName", "Insurance Service") &&
      <ListItemButton onClick={() => setOpenInsuranceService(!insuranceService)}>
        <ListItemIcon>
          <AssuredWorkloadIcon />
        </ListItemIcon>
        <ListItemText primary={t('InsuranceService')} />
        {insuranceService ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={insuranceService} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddInsuranceService" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Add Insurance Service") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddInsuranceService')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddGatePass" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Add Insurance GatePass") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('GatePass')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddExpense" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Add Insurance Expense") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('OurCost')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/InspectionList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Inspection List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('InspectionList')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/CompleteList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Complete Service") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('CompleteService')} />
            </ListItemButton>}
        </NavLink>
      </List>
      {/* <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/BillPaymentList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Bill Payment" />
          </ListItemButton>
        </NavLink>
      </List> */}
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/AddDeliveryPass" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Delivery Pass") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('DeliverPass')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/DeliveryPassReport" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Delivery Pass Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary={t('DeliverPassReport')} />
            </ListItemButton>}
        </NavLink>
      </List>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/Report" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary={t('IncServiceReport')} />
            </ListItemButton>}
        </NavLink>
      </List>




    </Collapse>


    <ListItemButton onClick={() => setIncReport(!incReport)}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary={t('InsuranceReport')} />
      {incReport ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={incReport} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/mainService/Insurance/IncPDFReport" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary={t('InsuranceCompanyReport')} />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


  </List>


  // { MASTER CONTROL }
  let masterControlList = <List>
    {Checkpermissions("permissionsCatName", "Company") &&
      <ListItemButton onClick={() => setOpenCompany(!openCompany)}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary={t('Company')} />
        {openCompany ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openCompany} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/Company/AddCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Company Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddCompany')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/setting/Company/ListCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Company List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('CompanyList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Business Location") &&
      <ListItemButton onClick={() => setOpenBusinesslocation(!businesslocation)}>
        <ListItemIcon>
          <AddBusinessIcon />
        </ListItemIcon>
        <ListItemText primary={t('BusinnesLocation')} />
        {businesslocation ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={businesslocation} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/BusinessLocation/AddBusinessLocation" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Business Location Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddBusinnesLocation')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/setting/BusinessLocation/ListBusinessLocation" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Business Location List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('BusinnesLocationList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "User") &&
      <ListItemButton onClick={() => setOpenUser(!openUser)}>
        <ListItemIcon>
          <PersonAddAlt1Icon />
        </ListItemIcon>
        <ListItemText primary={t('User')} />
        {openUser ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openUser} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/User/AddUser" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "User Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddUser')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/setting/user/ListUser" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "User List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('UserList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Service") &&
      <ListItemButton onClick={() => setOpenService(!service)}>
        <ListItemIcon>
          <SettingsSuggestIcon />
        </ListItemIcon>
        <ListItemText primary={t('Service')} />
        {service ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={service} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/service/AddService" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Service Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddService')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/masterControl/service/ListService" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Service List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('ServiceList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Insurance Company") &&
      <ListItemButton onClick={() => setOpenInsuranceCompany(!insuranceCompany)}>
        <ListItemIcon>
          <BeenhereIcon />
        </ListItemIcon>
        <ListItemText primary={t('InsuranceCompany')} />
        {insuranceCompany ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={insuranceCompany} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/insuranceCompany/AddInsuranceCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Company Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddInsuranceCompany')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/masterControl/insuranceCompany/ListInsuranceCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Insurance Company List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('InsuranceCompanyList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Inspector") &&
      <ListItemButton onClick={() => setOpenInspector(!inspector)}>
        <ListItemIcon>
          <ManIcon />
        </ListItemIcon>
        <ListItemText primary={t('Inspector')} />
        {inspector ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={inspector} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/inspector/AddInspector" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Inspector Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddInspector')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/masterControl/inspector/ListInspector" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Inspector List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('InspectorList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Employee") &&
      <ListItemButton onClick={() => setOpenEmployee(!employee)}>
        <ListItemIcon>
          <AssignmentIndIcon />
        </ListItemIcon>
        <ListItemText primary={t('Employee')} />
        {employee ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={employee} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/masterControl/employee/AddEmployee" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Employee Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddEmployee')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/masterControl/employee/EmployeeView" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Employee List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('EmployeeList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Permission") &&
      <NavLink to="/setting/permission/AddPermission" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <SecurityIcon />
          </ListItemIcon>
          <ListItemText primary={t('Permission')} />
        </ListItemButton>
      </NavLink>}



    {/* {Checkpermissions("permissionsCatName", "Backup") &&
      <NavLink to="/setting/BackUp" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary={t('Backup')} />
        </ListItemButton>
      </NavLink>} */}


  </List>;


  // { STORE }
  let StoreList = <List>
    {Checkpermissions("permissionsCatName", "Product") &&
      <ListItemButton onClick={() => setOpenProduct(!product)}>
        <ListItemIcon>
          <Inventory2Icon />
        </ListItemIcon>
        <ListItemText primary={t('Product')} />
        {product ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={product} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/store/product/AddProduct" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Product Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddProduct')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/store/product/ListProduct" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Product List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('ProductList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Purchase Order") &&
      <ListItemButton onClick={() => setOpenPurchaseOrder(!purchaseOrder)}>
        <ListItemIcon>
          <ShoppingBasketIcon />
        </ListItemIcon>
        <ListItemText primary={t('PurchaseOrder')} />
        {purchaseOrder ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={purchaseOrder} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/store/PurchaseOrder/AddPurchaseOrder" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Purchase Order Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('AddPurchaseOrder')} />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/store/PurchaseOrder/ViewPurchaseOrder" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Purchase Order List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary={t('PurchaseOrderList')} />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>


    {Checkpermissions("permissionsCatName", "Store Ledger") &&
      <NavLink to="/store/storeLedger" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <ListItemText primary={t('StoreLedger')} />
        </ListItemButton>
      </NavLink>}


  </List>


  if (props.moduleKey === 'mainService' || window.localStorage.getItem('module_key') === 'mainService') return mainServiceList;
  else if (props.moduleKey === 'masterControl' || window.localStorage.getItem('module_key') === 'masterControl') return masterControlList;
  else if (props.moduleKey === 'store' || window.localStorage.getItem('module_key') === 'store') return StoreList;
  else return mainServiceList;
}; 
