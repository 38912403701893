import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { IncPDFReportApi } from "./insuranceServiceAction";
import { GetInsuranceCompanyApi } from "../../MasterControl/InsuranceCompany/insuranceCompanyAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Select from "react-select";
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import Spinner from "react-bootstrap/Spinner";
import '../../../../../il8n';


const IncPDFReport = (props) => {
    const { t, i18n } = useTranslation();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [status, setStatusValue] = useState("");
    const [insuranceCompanyId, setIncCompanyId] = useState();
    const [insuranceCompanyName, setIncCompanyName] = useState();


    const handleChangeStatus = (selectedOption) => {
        setStatusValue(selectedOption.value);
    };
    const getStatusList = [
        { value: "Work In Process", label: "Work In Process" },
        { value: "Inspection Approved", label: "Inspection Approved" },
        { value: "Completed", label: "Completed" },
    ];

    const handleChangeIncCompany = (selectedOption) => {
        setIncCompanyId(selectedOption.value);
        setIncCompanyName(selectedOption.label);
    };
    const getIncCompanyList = !_.isEmpty(props.insuranceCompanyDataList) && props.insuranceCompanyDataList.map((x) => {
        let data = { value: x._id, label: x.companyName };
        return data;
    });

    useEffect(() => {
        props.GetInsuranceCompanyApi()
        props.setHeading("Insurance Report");
    }, []);

    function searchData() {
        props.IncPDFReportApi({
            startDate: _.isEmpty(startDate) ? "" : startDate,
            endDate: _.isEmpty(endDate) ? "" : endDate,
            status: status,
            insuranceCompanyId: insuranceCompanyId,
            insuranceCompanyName: insuranceCompanyName,
        });
    }

    return (<>
        {props.isFetchingInsuranceCompany ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('StartDate')}</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('EndDate')}</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('InsuranceCompany')} *</b></Form.Label>
                                <Select
                                    placeholder="Insurance Company"
                                    onChange={handleChangeIncCompany}
                                    options={getIncCompanyList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label><b>{t('SelectStatus')} *</b></Form.Label>
                                <Select
                                    placeholder="Select Status"
                                    onChange={handleChangeStatus}
                                    options={getStatusList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div style={{ marginTop: '40px', textAlign: 'center' }}>
                        {_.isEmpty(status) || _.isEmpty(insuranceCompanyId) ? (
                            <Button variant="contained" endIcon={<PrintIcon />} size="large" disabled> GENRATE PDF </Button>
                        ) : (
                            <Button variant="contained" endIcon={<PrintIcon />} size="large" onClick={(e) => searchData()}> GENRATE PDF </Button>
                        )}
                    </div>

                </div>
            </Container>
        )}
    </>);
};

const mapDispatchToProps = (dispatch) => {
    return {
        IncPDFReportApi: (data) => dispatch(IncPDFReportApi(data)),
        GetInsuranceCompanyApi: () => dispatch(GetInsuranceCompanyApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
}
const mapStateToProps = (state, ownProps) => ({
    insuranceCompanyDataList: state.InsuranceCompanyReducer.insuranceCompanyDataList,
    isFetchingInsuranceCompany: state.InsuranceCompanyReducer.isFetchingInsuranceCompany,
})

export default connect(mapStateToProps, mapDispatchToProps)(IncPDFReport);