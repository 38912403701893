import React, { useState } from "react";
import Select from "react-select";
import Swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { Container, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { useEffect } from "react";
import { isEmpty, toLower } from "lodash";
import { addUserRes } from "./user.actions";
import { GetCompanyApi } from "../Company/companyAction";
import { getWarehouseWithResApi } from "../BusinessLocation/BusinessLocationAction";
import { setHeading } from "../../../accounts/account.actions";
import { useTranslation } from "react-i18next";
import '../../../../../il8n';


const AddUser = (props) => {
  const { t, i18n } = useTranslation();
  const [userName, setUserName] = useState("");
  const [passwordUser, setPasswordUser] = useState("");
  const [phone, setPhone] = useState("");
  const [roleName, setRoleName] = useState("");
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  function uploadUser() {
    props.addUser({
      companyId: companyId,
      businessId: warehouseId,
      userName: userName,
      password: passwordUser,
      phone: phone,
      role: toLower(roleName),
      firstName: userName,
      companyName: companyName,
      warehouseName: warehouseName,
    },
      handleVisible,
      handleDangerVisible
    );
    setCompanyName("")
    setCompanyId("")
    setWarehouseId("")
    setWarehouseName("")
    setUserName("")
    setPasswordUser("")
    setPhone("")
    setRoleName("")
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  let rollData = [
    {
      roll_id: 1,
      roll_name: "User",
    },
    {
      roll_id: 2,
      roll_name: "Admin",
    }
  ];
  const handleChangeRoll = (selectedOption) => {
    setRoleName(selectedOption.label);
  };
  const rollList = !isEmpty(rollData) && rollData.map((x) => {
    let data = { value: x.roll_id, label: x.roll_name };
    return data;
  });

  const handleChangeCompany = (selectedOption) => {
    setCompanyId(selectedOption.value);
    setCompanyName(selectedOption.label);
    props.getWarehouseWithResApi(selectedOption.value);
  };
  const getCompanyList = !isEmpty(props.companyDataList) && props.companyDataList.map((x) => {
    let data = { value: x._id, label: x.companyName };
    return data;
  });

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !isEmpty(props.warehouseListWithResId.data) && props.warehouseListWithResId.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });


  useEffect(() => {
    props.setHeading("Add User")
    props.GetCompanyApi()
  }, []);

  return (
    <>
      {props.isFetchingCompany || props.isAddingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('SelectCompany')} *</b></Form.Label>
                  <Select
                    isDisabled={isEmpty(props.companyDataList)}
                    placeholder="Select Company"
                    onChange={handleChangeCompany}
                    options={getCompanyList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('SelectBusinessLocation')} *</b> </Form.Label>
                  <Select
                    placeholder="Select Business Location"
                    isDisabled={isEmpty(props.warehouseListWithResId) || isEmpty(companyId)}
                    onChange={handleChangeWarehouse}
                    options={getWarehouseList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('UserName')} *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="User Name"
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('Password')} *</b></Form.Label>
                  <Form.Control
                    type="password"
                    minLength={8}
                    placeholder="Password"
                    onChange={(e) => setPasswordUser(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('Phone')} *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>{t('SelectRole')} *</b> </Form.Label>
                  <Select
                    isDisabled={isEmpty(rollData)}
                    placeholder="Select Role"
                    onChange={handleChangeRoll}
                    options={rollList}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              {isEmpty(userName) || isEmpty(passwordUser) || isEmpty(companyId) || isEmpty(roleName) || isEmpty(warehouseId) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadUser}> SAVE </Button>
              )}
            </div>

          </Form>
        </Container>)}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (data, handleVisible, handleDangerVisible) => dispatch(addUserRes(data, handleVisible, handleDangerVisible)),
    GetCompanyApi: () => dispatch(GetCompanyApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    getWarehouseWithResApi: (id) => dispatch(getWarehouseWithResApi(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  addUser: state.UserReducer.addUser,
  isAddingUser: state.UserReducer.isAddingUser,
  companyDataList: state.CompanyReducer.companyDataList,
  isFetchingCompany: state.CompanyReducer.isFetchingCompany,
  warehouseListWithResId: state.WarehouseReducer.warehouseListWithResId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);