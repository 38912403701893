import * as React from "react";
import { connect } from "react-redux";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Container } from "react-bootstrap";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";
import Menu from "./menu.js";
import { Outlet } from "react-router-dom";
import IconMenu from "./IconMenu.js";
import { Navbar } from "react-bootstrap";
const ColorModeContext = React.createContext({ toggleColorMode: () => { } });
const drawerWidth = 240;



export function Dashboard1(props) {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }} >
        <Navbar style={{ paddingLeft: '3%', paddingRight: '3%', backgroundColor: '#81abd3' }} >
          <Container>
            <Navbar.Brand style={{ color: 'white' }}><b>{props.props.heading}</b></Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <Menu moduleKey={props.props.moduleKey} heading={props.props.heading} permissions={permissions} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}

function Dashboard(props) {
  const [mode, setMode] = React.useState("light");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
      getColorMode: () => {
        //getMode(mode, state, dispatch)
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Dashboard1 props={props} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  // setHeading: (data) => dispatch(setHeading(data))
});

const mapStateToProps = (state, ownProps, number) => ({
  moduleKey: state.userReducer.moduleKey,
  heading: state.userReducer.heading
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);