import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { GetInsuranceServiceData, GetInsuranceServiceWithIdApi, AddInsuranceExpenseApi } from "./insuranceServiceAction";
import { getProductData } from "../../MasterControl/Product/productAction";
import { GetServiceApi } from "../../MasterControl/Service/serviceAction";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SendIcon from '@mui/icons-material/Send';
import SaveButton from '@mui/material/Button';
import '../../../../../il8n';


const AddExpense = (props) => {
    const { t, i18n } = useTranslation();
    const [mainServiceId, setMainServiceId] = useState();
    const [mainServiceName, setMainServiceName] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputFieldProduct, setInputFieldProduct] = useState([{
        productId: 0,
        productName: '',
        quantity: '',
        salePrice: 0,
        totalSalePrice: 0,
    }]);
    const [inputFieldService, setInputFieldService] = useState([{
        productId: 0,
        productName: '',
        quantity: '',
        salePrice: 0,
        totalSalePrice: 0,
    }]);
    const [inputFieldExtraService, setInputFieldExtraService] = useState([{
        description: '',
        quantity: 0,
        price: 0,
        totalPrice: 0,
    }]);

    const filteredInputFieldService = inputFieldService.filter(obj => {
        return !Object.values(obj).some(value => value === 0 || value === "");
    });
    const filteredInputFieldProduct = inputFieldProduct.filter(obj => {
        return !Object.values(obj).some(value => value === 0 || value === "");
    });
    const filteredInputFieldExtraService = inputFieldExtraService.filter(obj => {
        return !Object.values(obj).some(value => value === 0 || value === "");
    });

    function SaveExpense() {
        props.AddInsuranceExpenseApi({
            cashServiceId: mainServiceId,
            productExpenseData: filteredInputFieldProduct,
            serviceExpenseData: filteredInputFieldService,
            extraServiceExpenseData: filteredInputFieldExtraService,
        },
            handleVisible,
            handleDangerVisible
        );
        setMainServiceId("")
        setMainServiceName("")
        setInputFieldProduct([{
            productId: 0,
            productName: '',
            quantity: 0,
            salePrice: 0,
            totalSalePrice: 0,
        }]);
        setInputFieldService([{
            productId: 0,
            productName: '',
            quantity: 0,
            salePrice: 0,
            totalSalePrice: 0,
        }]);
        setInputFieldExtraService([{
            description: '',
            quantity: 0,
            price: 0,
            totalPrice: 0,
        }]);
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };


    const handleChangeMainService = (selectedOption) => {
        setMainServiceId(selectedOption.value);
        setMainServiceName(selectedOption.label);
        props.GetInsuranceServiceWithIdApi(selectedOption.value)
    };
    const filterMainServiceList = _.filter(props.insuranceServiceDataList, { status: 'Work In Process' }).sort((a, b) => b.seqNumber - a.seqNumber)
    const getMainServiceList = !_.isEmpty(filterMainServiceList) && filterMainServiceList.map((x) => {
        let data = { value: x._id, label: `${x.seqNumber} / ${x.vehicleNumber} / ${x.vehicleMade} / ${x.customerName}` };
        return data;
    });


    const handleChangeProduct = (e, index, type) => {
        let z = _.find(props.productDataList, (x) => x._id == e.value);
        const list = [...inputFieldProduct];
        if (type === "intname") list[index]["productId"] = e.value;
        if (type === "intname") list[index]["productName"] = e.label;
        if (type === "intname") list[index]["salePrice"] = Number(z.salePrice);
        if (type === "intquantity") list[index]["quantity"] = Number(e.target.value);
        if (type === 'intquantity') list[index]['totalSalePrice'] = Number(Math.round((list[index]['salePrice'] * list[index]['quantity']) * 100) / 100);

        setInputFieldProduct(list);
    };
    console.log(inputFieldProduct, "ppppppppppppppppppppppp")
    const getProductList = !_.isEmpty(props.productDataList) && props.productDataList.map((x) => {
        let data = { value: x._id, label: `${x.name}` };
        return data;
    });
    const handleAddInputProduct = () => {
        setInputFieldProduct([...inputFieldProduct, {
            productId: 0,
            productName: '',
            quantity: '',
            salePrice: 0,
        }]);
    };
    const handleRemoveInputProduct = (index) => {
        const list = [...inputFieldProduct];
        list.splice(index, 1);
        setInputFieldProduct(list);
    };


    const handleChangeService = (e, index, type) => {
        let z = _.find(props.serviceDataList, (x) => x._id == e.value);
        const list = [...inputFieldService];
        if (type === "intname") list[index]["productId"] = e.value;
        if (type === "intname") list[index]["productName"] = e.label;
        if (type === "intname") list[index]["salePrice"] = Number(z.price);
        if (type === "intquantity") list[index]["quantity"] = Number(e.target.value);
        if (type === 'intquantity') list[index]['totalSalePrice'] = Number(Math.round((list[index]['salePrice'] * list[index]['quantity']) * 100) / 100);

        setInputFieldService(list);
    };
    console.log(inputFieldService, "ssssssssssssssssssssssssss")
    const getServiceList = !_.isEmpty(props.serviceDataList) && props.serviceDataList.map((x) => {
        let data = { value: x._id, label: `${x.serviceName}` };
        return data;
    });
    const handleAddInputService = () => {
        setInputFieldService([...inputFieldService, {
            productId: 0,
            productName: '',
            quantity: '',
            salePrice: 0,
        }]);
    };
    const handleRemoveInputService = (index) => {
        const list = [...inputFieldService];
        list.splice(index, 1);
        setInputFieldService(list);
    };


    const handleChangeExtraService = (e, index, type) => {
        const list = [...inputFieldExtraService];
        if (type === "description") list[index]["description"] = e.target.value;
        if (type === "quantity") list[index]["quantity"] = Number(e.target.value);
        if (type === "price") list[index]["price"] = Number(e.target.value);
        if (type === 'price' || type === 'quantity') list[index]['totalPrice'] = Number(Math.round((list[index]['price'] * list[index]['quantity']) * 100) / 100);

        setInputFieldExtraService(list);
    };
    console.log(inputFieldExtraService, "eeeeeeeeeeeeeeeeeeeee")
    const handleAddInputExtraService = () => {
        setInputFieldExtraService([...inputFieldExtraService, {
            description: '',
            quantity: 0,
            price: 0,
            totalPrice: 0,
        }]);
    };
    const handleRemoveInputExtraService = (index) => {
        const list = [...inputFieldExtraService];
        list.splice(index, 1);
        setInputFieldExtraService(list);
    };


    useEffect(() => {
        props.setHeading("Our Cost");
        props.GetInsuranceServiceData()
        props.getProductData()
        props.GetServiceApi()
    }, []);

    return (<>
        {props.isFetchingInsuranceService || props.isAddingInsuranceServiceExpense ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="garana">
                            <Form.Label><b>{t('SelectService')} *</b> </Form.Label>
                            <Select
                                isDisabled={_.isEmpty(filterMainServiceList)}
                                placeholder="Select Service"
                                onChange={handleChangeMainService}
                                options={getMainServiceList}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {props.isFetchingInsuranceServiceWithId ? (
                    <div className="loader-div">
                        <Spinner
                            variant="primary"
                            animation="border"
                            size="xl"
                            role="status"
                            aria-hidden="true"
                        />
                    </div>
                ) : (
                    <div hidden={_.isEmpty(mainServiceName)}>
                        {props.isFetchingInsuranceServiceWithId ? (
                            <div className="loader-div">
                                <Spinner
                                    variant="primary"
                                    animation="border"
                                    size="xl"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </div>
                        ) : (<>
                            <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px' }}>
                                <Row>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('VehicleNumber')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].vehicleNumber}</span> </b></Col>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('VehicleMade')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].vehicleMade}</span> </b></Col>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('VehicleModel')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].vehicleModel}</span> </b></Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('CustomerName')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].customerName}</span> </b></Col>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('CustomerNumber')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].customerNumber}</span> </b></Col>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('SelectType')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].serviceType}</span> </b></Col>
                                </Row>
                                <Row>
                                    <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>{t('CustomerEmail')}: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.insuranceServiceDataListWithId[0]) ? '' : props.insuranceServiceDataListWithId[0].customerEmail}</span> </b></Col>
                                </Row>
                            </div>


                            <Row style={{ marginTop: '30px' }}>
                                <Col xl="6" lg="6" md="6" sm="6" style={{ borderRightStyle: "solid" }} >
                                    <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> {t('Product')} </Col>
                                    {inputFieldProduct.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="garana">
                                                            <Form.Label><b>{t('SelectProduct')} *</b> </Form.Label>
                                                            <Select
                                                                placeholder="Select Product"
                                                                name="productId"
                                                                onChange={(e) => handleChangeProduct(e, i, "intname")}
                                                                options={getProductList}
                                                                isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldProduct, (x) => x.productId == option.value))}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('AddQuantity')} *</b> </Form.Label>
                                                            <Form.Control
                                                                // min={0}
                                                                type="number"
                                                                placeholder="Add Quantity"
                                                                name="quantity"
                                                                value={item.quantity}
                                                                onChange={(e) => handleChangeProduct(e, i, "intquantity")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('SalePrice')} *</b> </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="number"
                                                                placeholder="Sale Price"
                                                                name="salePrice"
                                                                value={item.salePrice}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {inputFieldProduct.length - 1 == i && (
                                                            <Button title="Add Product" onClick={handleAddInputProduct} style={{ float: "right" }} >
                                                                <AddIcon />
                                                            </Button>
                                                        )}
                                                        {inputFieldProduct.length !== 1 && (
                                                            <Button title="Remove Product" onClick={() => handleRemoveInputProduct(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                <RemoveIcon />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </React.Fragment>)
                                    })}
                                </Col>


                                <Col xl="6" lg="6" md="6" sm="6" >
                                    <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> {t('Service')} </Col>
                                    {inputFieldService.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3" controlId="garana">
                                                            <Form.Label><b>{t('SelectService')} *</b> </Form.Label>
                                                            <Select
                                                                placeholder="Select Service"
                                                                name="productId"
                                                                onChange={(e) => handleChangeService(e, i, "intname")}
                                                                options={getServiceList}
                                                                isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldService, (x) => x.productId == option.value))}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('AddQuantity')} *</b> </Form.Label>
                                                            <Form.Control
                                                                // min={0}
                                                                type="number"
                                                                placeholder="Add Quantity"
                                                                name="quantity"
                                                                value={item.quantity}
                                                                onChange={(e) => handleChangeService(e, i, "intquantity")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('SalePrice')} *</b> </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="number"
                                                                placeholder="Sale Price"
                                                                name="salePrice"
                                                                value={item.salePrice}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        {inputFieldService.length - 1 == i && (
                                                            <Button title="Add Product" onClick={handleAddInputService} style={{ float: "right" }} >
                                                                <AddIcon />
                                                            </Button>
                                                        )}
                                                        {inputFieldService.length !== 1 && (
                                                            <Button title="Remove Product" onClick={() => handleRemoveInputService(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                <RemoveIcon />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </React.Fragment>)
                                    })}
                                </Col>


                                <Col style={{ marginTop: '40px' }}>
                                    <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> {t('Service')} </Col>
                                    {inputFieldExtraService.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('Description')} *</b> </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Description"
                                                                name="description"
                                                                value={item.description}
                                                                onChange={(e) => handleChangeExtraService(e, i, "description")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('Quantity')} *</b> </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Quantity"
                                                                name="quantity"
                                                                value={item.quantity}
                                                                onChange={(e) => handleChangeExtraService(e, i, "quantity")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('Price')} *</b> </Form.Label>
                                                            <Form.Control
                                                                type="number"
                                                                placeholder="Price"
                                                                name="price"
                                                                value={item.price}
                                                                onChange={(e) => handleChangeExtraService(e, i, "price")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>{t('TotalPrice')}</b> </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="number"
                                                                placeholder="Total Price"
                                                                name="totalPrice"
                                                                value={item.totalPrice}
                                                                onChange={(e) => handleChangeExtraService(e, i, "totalPrice")}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col style={{ marginTop: '23px' }}>
                                                        {inputFieldExtraService.length - 1 === i && (
                                                            <Button title="Add Extra Service" onClick={handleAddInputExtraService} style={{ float: "right" }} >
                                                                <AddIcon />
                                                            </Button>
                                                        )}
                                                        {inputFieldExtraService.length !== 1 && (
                                                            <Button title="Remove Extra Service" onClick={() => handleRemoveInputExtraService(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                <RemoveIcon />
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </React.Fragment>)
                                    })}
                                </Col>

                                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                    {_.isEmpty(mainServiceId) ? (
                                        <SaveButton variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </SaveButton>
                                    ) : (
                                        <SaveButton variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveExpense}> SAVE </SaveButton>
                                    )}
                                </div>

                            </Row>
                        </>)}
                    </div>
                )}

            </Container>
        )}
    </>)
}

const mapDispatchToProps = (dispatch) => {
    return {
        AddInsuranceExpenseApi: (data, handleVisible, handleDangerVisible) => dispatch(AddInsuranceExpenseApi(data, handleVisible, handleDangerVisible)),
        GetInsuranceServiceData: () => dispatch(GetInsuranceServiceData()),
        GetInsuranceServiceWithIdApi: (id) => dispatch(GetInsuranceServiceWithIdApi(id)),
        getProductData: () => dispatch(getProductData()),
        GetServiceApi: () => dispatch(GetServiceApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    insuranceServiceDataList: state.InsuranceServiceReducer.insuranceServiceDataList,
    isFetchingInsuranceService: state.InsuranceServiceReducer.isFetchingInsuranceService,
    insuranceServiceDataListWithId: state.InsuranceServiceReducer.insuranceServiceDataListWithId,
    isFetchingInsuranceServiceWithId: state.InsuranceServiceReducer.isFetchingInsuranceServiceWithId,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    serviceDataList: state.ServiceReducer.serviceDataList,
    isFetchingService: state.ServiceReducer.isFetchingService,
    isAddingInsuranceServiceExpense: state.InsuranceServiceReducer.isAddingInsuranceServiceExpense,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddExpense);