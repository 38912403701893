import API from "../../../../../global/api";
import jsPDF from "jspdf";
// import moment from "moment";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";
import { textData } from "../../../../../global/test";
import "jspdf-autotable";


export const requestAddInsuranceService = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE",
    };
};
export const successAddInsuranceService = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE",
        payload: data,
    };
};
export const errorAddInsuranceService = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE",
    };
};
export const AddInsuranceServiceApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceService());
        API.post(`/insuranceService`, data).then((res) => {
            dispatch(successAddInsuranceService(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceService());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestInsuranceServiceData = () => {
    return {
        type: "REQUEST_GET_INSURANCE_SERVICE",
    };
};
export const successInsuranceServiceData = (data) => {
    return {
        type: "SUCCESS_GET_INSURANCE_SERVICE",
        payload: data,
    };
};
export const errorInsuranceServiceData = () => {
    return {
        type: "ERROR_GET_INSURANCE_SERVICE",
    };
};
export const GetInsuranceServiceData = () => {
    return (dispatch) => {
        dispatch(requestInsuranceServiceData());
        API.get(`/insuranceService`).then((res) => {
            let getData = res.data;
            dispatch(successInsuranceServiceData(getData));
        }).catch((error) => {
            dispatch(errorInsuranceServiceData());
        })
    };
};


export const requestInsuranceServiceDataWithId = () => {
    return {
        type: "REQUEST_GET_INSURANCE_SERVICE_WITH_ID",
    };
};
export const successInsuranceServiceDataWithId = (data) => {
    return {
        type: "SUCCESS_GET_INSURANCE_SERVICE_WITH_ID",
        payload: data,
    };
};
export const errorInsuranceServiceDataWithId = () => {
    return {
        type: "ERROR_GET_INSURANCE_SERVICE_WITH_ID",
    };
};
export const GetInsuranceServiceWithIdApi = (id) => {
    return (dispatch) => {
        dispatch(requestInsuranceServiceDataWithId());
        API.get(`/insuranceService/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successInsuranceServiceDataWithId(getData));
        }).catch((error) => {
            dispatch(errorInsuranceServiceDataWithId());
        })
    };
};


export const RequestDeleteInsuranceService = () => {
    return {
        type: "DELETE_REQUEST_INSURANCR_SERVICE",
    };
};
export const SucessDeleteInsuranceService = (user_id) => {
    return {
        type: "DELETE_SUCCESS_INSURANCR_SERVICE",
        user_id: user_id,
    };
};
export const ErrorDeleteInsuranceService = () => {
    return {
        type: "DELETE_ERROR_INSURANCR_SERVICE",
    };
};
export const DeleteInsuranceServiceApi = (id) => {
    return dispatch => {
        dispatch(RequestDeleteInsuranceService());
        API.delete(`/insuranceService/${id}`).then(res => {
            dispatch(SucessDeleteInsuranceService(id))
        }).catch((error) => {
            dispatch(ErrorDeleteInsuranceService())
        });
    }
};


export const RequestUpdateServiceStatus = () => {
    return {
        type: "UPDATE_REQUEST_STATUS",
    };
};
export const SucessUpdateServiceStatus = (data) => {
    return {
        type: "UPDATE_SUCCESS_STATUS",
        payload: data,
    };
};
export const ErrorUpdateServiceStatus = () => {
    return {
        type: "UPDATE_ERROR_STATUS",
    };
};
export const UpdateServiceStatusApi = (status, id) => {
    return dispatch => {
        dispatch(RequestUpdateServiceStatus());
        API.put(`/insuranceService/update_service_status/${id}`, {
            status: status,
        }, {
        }).then(res => {
            dispatch(SucessUpdateServiceStatus([status]))
        }).catch((error) =>
            dispatch(ErrorUpdateServiceStatus()));
    }
};


export const InsuranceServiceEnglishPDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Insurance Service Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
        const srNo = "Invoice No: " + data.seqNumber;
        const InsuranceCompany = "Insurance Company: " + data.incuranceCompanyDetails.companyName;
        const businessName = "Business Location: " + data.businessLocationDetails.businessName;
        const status = "Status: " + data.status;
        const vehicleNumber = "Vehicle Number: " + data.vehicleNumber;
        const vehicleMade = "Vehicle Made: " + data.vehicleMade;
        const vehicleModel = "Vehicle Model: " + data.vehicleModel;
        const customerName = "Customer Name: " + data.customerName;
        const customerNumber = "Customer Number: " + data.customerNumber;
        const customerEmail = "Customer Email: " + data.customerEmail;
        const InstructorName = "Instructor Name: " + data.gatePassDetails.issuedBy;
        const date = "Date: " + data.date;

        const headers = [[
            "Sr",
            "Product Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const prodactData = _.isEmpty(data.productExpenseData) ? '' : data.productExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "Total", "", "", _.sumBy(prodactData, '4')])
        }
        let ProductContent = {
            startY: 300,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);

        const ServiceHeaders = [[
            "Sr",
            "Service Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const ServiceData = _.isEmpty(data.serviceExpenseData) ? '' : data.serviceExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(ServiceData)) {
            ServiceData.push(["", "Total", "", "", _.sumBy(ServiceData, '4')])
        }
        let ServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ServiceHeaders,
            styles: { fontSize: 11 },
            body: ServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ServiceContent);

        const ExtraServiceHeaders = [[
            "Sr",
            "Description",
            "Quantity",
            "Price",
            "Total Price",
        ]];
        const ExtraServiceData = _.isEmpty(data.extraServiceExpenseData) ? '' : data.extraServiceExpenseData.map((elt, i) => [
            i + 1,
            elt.description,
            elt.quantity,
            elt.price,
            elt.totalPrice,
        ]);
        if (!_.isEmpty(ExtraServiceData)) {
            ExtraServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ExtraServiceData, '4') * 100) / 100])
        }
        let ExtraServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ExtraServiceHeaders,
            styles: { fontSize: 11 },
            body: ExtraServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ExtraServiceContent);


        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyVAT, 150, 73, 0, 20);
        doc.text(companyNtn, 150, 88, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "bold");
        doc.text(title, 210, 120);
        doc.setFont("times", "normal");
        doc.text(srNo, 50, 150, 0, 20);
        doc.text(InsuranceCompany, 330, 150, 0, 20);
        doc.text(businessName, 50, 170, 0, 20);
        doc.text(status, 330, 170, 0, 20);
        doc.text(vehicleNumber, 50, 190, 0, 20);
        doc.text(vehicleMade, 330, 190, 0, 20);
        doc.text(vehicleModel, 50, 210, 0, 20);
        doc.text(customerName, 330, 210, 0, 20);
        doc.text(customerNumber, 50, 230, 0, 20);
        doc.text(customerEmail, 330, 230, 0, 20);
        doc.text(date, 50, 250, 0, 20);
        doc.text(InstructorName, 50, 270, 0, 20);

        doc.text(`Total Expense = ${data.totalExpense}`, 50, doc.lastAutoTable.finalY + 20);
        doc.text(`Vat (5%) = ${data.vatTotal}`, 50, doc.lastAutoTable.finalY + 40);
        doc.text(`Final Total = ${data.finalTotal}`, 50, doc.lastAutoTable.finalY + 60);

        doc.setProperties({ title: `Insurance Service Report` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const InsuranceServiceArabicPDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        doc.addFileToVFS("Amiri-Regular.ttf", textData);
        doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
        doc.setFont("Amiri");
        const title = "تفاصيل خدمة التأمين";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
        const srNo = "رقم الفاتورة:" + data.seqNumber;
        const InsuranceCompany = "شركة التأمين: " + data.incuranceCompanyDetails.companyName;
        const businessName = "موقع العمل: " + data.businessLocationDetails.businessName;
        const status = "حالة: " + data.status;
        const vehicleNumber = "رقم السيارة: " + data.vehicleNumber;
        const vehicleMade = "مركبة مصنوعة: " + data.vehicleMade;
        const vehicleModel = "طراز السيارة: " + data.vehicleModel;
        const customerName = "اسم العميل: " + data.customerName;
        const customerNumber = "رقم العميل: " + data.customerNumber;
        const customerEmail = "بريد إلكتروني للعميل: " + data.customerEmail;
        const InstructorName = "اسم المدرب: " + data.gatePassDetails.issuedBy;
        const date = "تاريخ: " + data.date;


        const headers = [[
            "رقم التسلسل",
            "اسم المنتج",
            "كمية",
            "سعر",
            "إجمالي قيمة البيع",
        ]];
        const prodactData = _.isEmpty(data.productExpenseData) ? '' : data.productExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "Total", "", "", _.sumBy(prodactData, '4')])
        }
        let ProductContent = {
            startY: 300,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ProductContent);

        const ServiceHeaders = [[
            "رقم التسلسل",
            "اسم الخدمة",
            "كمية",
            "سعر",
            "إجمالي قيمة البيع",
        ]];
        const ServiceData = _.isEmpty(data.serviceExpenseData) ? '' : data.serviceExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(ServiceData)) {
            ServiceData.push(["", "Total", "", "", _.sumBy(ServiceData, '4')])
        }
        let ServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ServiceHeaders,
            styles: { fontSize: 11 },
            body: ServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ServiceContent);

        const ExtraServiceHeaders = [[
            "رقم التسلسل",
            "وصف",
            "كمية",
            "سعر",
            "السعر الاجمالي",
        ]];
        const ExtraServiceData = _.isEmpty(data.extraServiceExpenseData) ? '' : data.extraServiceExpenseData.map((elt, i) => [
            i + 1,
            elt.description,
            elt.quantity,
            elt.price,
            elt.totalPrice,
        ]);
        if (!_.isEmpty(ExtraServiceData)) {
            ExtraServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ExtraServiceData, '4') * 100) / 100])
        }
        let ExtraServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ExtraServiceHeaders,
            styles: { fontSize: 11 },
            body: ExtraServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ExtraServiceContent);

        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyVAT, 150, 73, 0, 20);
        doc.text(companyNtn, 150, 88, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 210, 120);
        doc.text(srNo, 50, 150, 0, 20);
        doc.text(InsuranceCompany, 330, 150, 0, 20);
        doc.text(businessName, 50, 170, 0, 20);
        doc.text(status, 330, 170, 0, 20);
        doc.text(vehicleNumber, 50, 190, 0, 20);
        doc.text(vehicleMade, 330, 190, 0, 20);
        doc.text(vehicleModel, 50, 210, 0, 20);
        doc.text(customerName, 330, 210, 0, 20);
        doc.text(customerNumber, 50, 230, 0, 20);
        doc.text(customerEmail, 330, 230, 0, 20);
        doc.text(date, 50, 250, 0, 20);
        doc.text(InstructorName, 50, 270, 0, 20);

        doc.text(`إجمالي النفقات = ${data.totalExpense}`, 50, doc.lastAutoTable.finalY + 20);
        doc.text(`حوض = ${data.vatTotal}`, 50, doc.lastAutoTable.finalY + 40);
        doc.text(`المجموع النهائي = ${data.finalTotal}`, 50, doc.lastAutoTable.finalY + 60);

        doc.setProperties({ title: `Insurance Service Report` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const requestAddInsuranceGatePass = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_GATEPASS",
    };
};
export const successAddInsuranceGatePass = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_GATEPASS",
        payload: data,
    };
};
export const errorAddInsuranceGatePass = () => {
    return {
        type: "ERROR_ADD_INSURANCE_GATEPASS",
    };
};
export const AddInsuranceGatePassApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceGatePass());
        API.post(`/insuranceService/add_gatePass_details`, data).then((res) => {
            dispatch(successAddInsuranceGatePass(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceGatePass());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddInsuranceServiceDeliveryPass = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
    };
};
export const successAddInsuranceServiceDeliveryPass = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
        payload: data,
    };
};
export const errorAddInsuranceServiceDeliveryPass = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE_DELIVERY_PASS",
    };
};
export const AddInsuranceDeliveryPassApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddInsuranceServiceDeliveryPass());
        API.post(`/insuranceService/add_deliveryPass`, data).then((res) => {
            dispatch(successAddInsuranceServiceDeliveryPass(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddInsuranceServiceDeliveryPass());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddCashServiceExpense = () => {
    return {
        type: "REQUEST_ADD_INSURANCE_SERVICE_EXPENSE",
    };
};
export const successAddCashServiceExpense = (data) => {
    return {
        type: "SUCCESS_ADD_INSURANCE_SERVICE_EXPENSE",
        payload: data,
    };
};
export const errorAddCashServiceExpense = () => {
    return {
        type: "ERROR_ADD_INSURANCE_SERVICE_EXPENSE",
    };
};
export const AddInsuranceExpenseApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCashServiceExpense());
        API.post(`/insuranceService/add_expense`, data).then((res) => {
            dispatch(successAddCashServiceExpense(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCashServiceExpense());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const resetReducer = () => {
    return {
        type: "RESET_URL",
    };
};


export const requestIncServiceWithStatus = () => {
    return {
        type: "REQUEST_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const successIncServiceWithStatus = (data) => {
    return {
        type: "SUCCESS_SEARCH_INC_SERVICE_WITH_STATUS",
        payload: data,
    };
};
export const errorIncServiceWithStatus = () => {
    return {
        type: "ERROR_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const GetIncServiceWithStatusApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestIncServiceWithStatus());
        API.post(`/insuranceService/get_IncService_with_status`, data).then((res) => {
            let getData = res.data;
            dispatch(successIncServiceWithStatus(getData));
        }).catch((error) => {
            dispatch(errorIncServiceWithStatus());
        });
    };
};


export const DeliveryPassReportApi = (id) => {
    return (dispatch) => {
        dispatch(requestInsuranceServiceDataWithId());
        API.get(`/insuranceService/${id.mainServiceId}`).then((res) => {
            let data = res.data.data[0];

            if (id.type === 'Arabic') {
                const unit = "pt";
                const size = "A4";
                const orientation = "potrait";
                const doc = new jsPDF(orientation, unit, size);
                doc.addFileToVFS("Amiri-Regular.ttf", textData);
                doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
                doc.setFont("Amiri");
                const title = "تقرير تصريح التسليم";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
                const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
                const incuranceCompanyName = "اسم شركة التأمين: " + data.incuranceCompanyDetails.companyName;
                const claim = "مطالبة: " + data.gatePassDetails.claim;
                const date = "تاريخ: " + data.date;
                // const date = "Inspector Name: " ;
                const approvelDate = "تاريخ الموافقة: " + data.gatePassDetails.approvelDate;
                const deliverDate = "تاريخ التسليم او الوصول: " + data.deliveryPassDetails.deliverDate;
                const customerName = "اسم العميل: " + data.customerName;
                const vehicleNumber = "رقم السيارة: " + data.vehicleNumber;
                const residenceID = "معرف الإقامة: " + data.deliveryPassDetails.residenceID;

                doc.addImage(logo, "PNG", 40, 15, 80, 80);
                doc.text(companyName, 150, 40, 0, 20);
                doc.setFontSize(13);
                doc.text(companyAddress, 150, 58, 0, 20);
                doc.text(companyVAT, 150, 73, 0, 20);
                doc.text(companyNtn, 150, 88, 0, 20);
                doc.setFontSize(12);
                doc.text(title, 240, 120);
                doc.text(incuranceCompanyName, 50, 150, 0, 20);
                doc.text(date, 50, 170, 0, 20);
                doc.text(approvelDate, 50, 190, 0, 20);
                doc.text(deliverDate, 50, 210, 0, 20);
                doc.text(customerName, 50, 230, 0, 20);
                doc.text(vehicleNumber, 50, 250, 0, 20);
                doc.text(residenceID, 50, 270, 0, 20);
                doc.text(claim, 50, 290, 0, 20);

                doc.setProperties({ title: `Delivery Pass Report` });
                let blob = doc.output("blob");
                window.open(URL.createObjectURL(blob));
            } else {
                const unit = "pt";
                const size = "A4";
                const orientation = "potrait";
                const doc = new jsPDF(orientation, unit, size);
                const title = "Delivery Pass Report";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
                const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
                const incuranceCompanyName = "Insurance Company Name: " + data.incuranceCompanyDetails.companyName;
                const claim = "Claim: " + data.gatePassDetails.claim;
                const date = "Date: " + data.date;
                // const date = "Inspector Name: " ;
                const approvelDate = "Approval Date: " + data.gatePassDetails.approvelDate;
                const deliverDate = "Delivery Date: " + data.deliveryPassDetails.deliverDate;
                const customerName = "Customer Name: " + data.customerName;
                const vehicleNumber = "Vehicle Number: " + data.vehicleNumber;
                const residenceID = "Residence ID: " + data.deliveryPassDetails.residenceID;

                doc.addImage(logo, "PNG", 40, 15, 80, 80);
                doc.text(companyName, 150, 40, 0, 20);
                doc.setFontSize(13);
                doc.setFont("times", "normal");
                doc.text(companyAddress, 150, 58, 0, 20);
                doc.text(companyVAT, 150, 73, 0, 20);
                doc.text(companyNtn, 150, 88, 0, 20);
                doc.setFontSize(12);
                doc.setFont("times", "bold");
                doc.text(title, 240, 120);
                doc.setFont("times", "normal");
                doc.text(incuranceCompanyName, 50, 150, 0, 20);
                doc.text(date, 50, 170, 0, 20);
                doc.text(approvelDate, 50, 190, 0, 20);
                doc.text(deliverDate, 50, 210, 0, 20);
                doc.text(customerName, 50, 230, 0, 20);
                doc.text(vehicleNumber, 50, 250, 0, 20);
                doc.text(residenceID, 50, 270, 0, 20);
                doc.text(claim, 50, 290, 0, 20);

                doc.setProperties({ title: `Delivery Pass Report` });
                let blob = doc.output("blob");
                window.open(URL.createObjectURL(blob));
            }

            dispatch(successInsuranceServiceDataWithId(data));
        }).catch((error) => {
            console.log(error, "error")
            dispatch(errorInsuranceServiceDataWithId());
        });
    };
};


export const setEditFields = (data) => {
    return {
        type: "SET_EDIT_FIELDS",
        payload: data
    };
}


export const requestInsuranceReportPDF = () => {
    return {
        type: "REQUEST_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const successInsuranceReportPDF = (data) => {
    return {
        type: "SUCCESS_SEARCH_INC_SERVICE_WITH_STATUS",
        payload: data,
    };
};
export const errorInsuranceReportPDF = () => {
    return {
        type: "ERROR_SEARCH_INC_SERVICE_WITH_STATUS",
    };
};
export const IncPDFReportApi = (payload) => {
    return (dispatch) => {
        dispatch(requestInsuranceReportPDF());
        API.post(`/insuranceService/get_IncService_PDF_report`, payload).then((res) => {
            let data = res.data.data;
            const unit = "pt";
            const size = "A4";
            const orientation = "landscape";
            const doc = new jsPDF(orientation, unit, size);
            doc.setFontSize(11);
            const title = "Insurance Service Report";
            const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
            const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
            const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
            const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;

            const headers = [[
                // "Sr",
                "Invoice No.",
                "Date",
                "Vehicle Number",
                "Claim",
                "GatePass No",
                "Survey Date",
                "Approvel Date",
                "Delivery Date",
                "Expense Total",
                "Total",
                "Discount",
                "VAT",
                "Final Total",
                "Status"
            ]];
            const prodactData = _.isEmpty(data) ? '' : data.map((elt, i) => [
                // i + 1,
                elt.seqNumber,
                elt.date,
                elt.vehicleNumber,
                elt.gatePassDetails.claim,
                '',
                elt.gatePassDetails.survayDate,
                elt.gatePassDetails.approvelDate,
                elt.deliveryPassDetails.deliverDate,
                elt.totalExpense,
                elt.totalExpense,
                0,
                elt.vatTotal,
                elt.finalTotal,
                elt.status,
            ]);
            // if (!_.isEmpty(prodactData)) {
            //     prodactData.push(["", "", "", "Total", _.sumBy(prodactData, '4')])
            // }
            let ProductContent = {
                startY: 200,
                startX: 0,
                head: headers,
                styles: { fontSize: 10 },
                body: prodactData,
                theme: "grid",
                headStyles: { fillColor: "#2980ba" },
            };
            doc.autoTable(ProductContent);

            doc.addImage(logo, "PNG", 40, 15, 80, 80);
            doc.text(companyName, 150, 40, 0, 20);
            doc.setFontSize(12);
            doc.setFont("times", "normal");
            doc.text(companyAddress, 150, 58, 0, 20);
            doc.text(companyVAT, 150, 73, 0, 20);
            doc.text(companyNtn, 150, 88, 0, 20);
            doc.setFontSize(14);
            doc.setFont("times", "bold");
            doc.text(title, 370, 120);
            doc.text(`Start Date: ${payload.startDate}`, 50, 140);
            doc.text(`End Date: ${payload.endDate}`, 50, 160);
            doc.text(`Insurance Company Name: ${payload.insuranceCompanyName}`, 50, 180);
            doc.setFont("times", "normal");

            doc.setProperties({ title: `Insurance PDF Report` });
            var blob = doc.output("blob");
            window.open(URL.createObjectURL(blob));

            dispatch(successInsuranceReportPDF(data));
        }).catch((error) => {
            console.log(error, "error")
            dispatch(errorInsuranceReportPDF());
        });
    };
};