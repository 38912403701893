import API from "../../../../../global/api";
import jsPDF from "jspdf";
// import moment from "moment";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";
// import { baseURL } from "../../../../../global/api";
import { textData } from "../../../../../global/test";
import "jspdf-autotable";


export const requestAddCashService = () => {
    return {
        type: "REQUEST_ADD_CASH_SERVICE",
    };
};
export const successAddCashService = (data) => {
    return {
        type: "SUCCESS_ADD_CASH_SERVICE",
        payload: data,
    };
};
export const errorAddCashService = () => {
    return {
        type: "ERROR_ADD_CASH_SERVICE",
    };
};
export const AddCashServiceApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCashService());
        API.post(`/cashService`, data).then((res) => {
            dispatch(successAddCashService(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCashService());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestCashServiceData = () => {
    return {
        type: "REQUEST_GET_CASH_SERVICE",
    };
};
export const successCashServiceData = (data) => {
    return {
        type: "SUCCESS_GET_CASH_SERVICE",
        payload: data,
    };
};
export const errorCashServiceData = () => {
    return {
        type: "ERROR_GET_CASH_SERVICE",
    };
};
export const GetCashServiceApi = () => {
    return (dispatch) => {
        dispatch(requestCashServiceData());
        API.get(`/cashService`).then((res) => {
            let getData = res.data;
            dispatch(successCashServiceData(getData));
        }).catch((error) => {
            dispatch(errorCashServiceData());
        })
    };
};


export const requestCashServiceDataWithId = () => {
    return {
        type: "REQUEST_GET_CASH_SERVICE_WITH_ID",
    };
};
export const successCashServiceDataWithId = (data) => {
    return {
        type: "SUCCESS_GET_CASH_SERVICE_WITH_ID",
        payload: data,
    };
};
export const errorCashServiceDataWithId = () => {
    return {
        type: "ERROR_GET_CASH_SERVICE_WITH_ID",
    };
};
export const GetCashServiceWithIdApi = (id) => {
    return (dispatch) => {
        dispatch(requestCashServiceDataWithId());
        API.get(`/cashService/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successCashServiceDataWithId(getData));
        }).catch((error) => {
            dispatch(errorCashServiceDataWithId());
        })
    };
};


export const RequestUpdateServiceStatus = () => {
    return {
        type: "UPDATE_REQUEST_STATUS",
    };
};
export const SucessUpdateServiceStatus = (data) => {
    return {
        type: "UPDATE_SUCCESS_STATUS",
        payload: data,
    };
};
export const ErrorUpdateServiceStatus = () => {
    return {
        type: "UPDATE_ERROR_STAUS",
    };
};
export const UpdateServiceStatusApi = (status, id) => {
    return dispatch => {
        dispatch(RequestUpdateServiceStatus());
        API.put(`/cashService/update_service_status/${id}`, {
            status: status,
        }, {
        }).then(res => {
            dispatch(SucessUpdateServiceStatus([status]))
        }).catch((error) =>
            dispatch(ErrorUpdateServiceStatus()));
    }
};


export const RequestDeleteCashService = () => {
    return {
        type: "DELETE_REQUEST_SERVICE",
    };
};
export const SucessDeleteCashService = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SERVICE",
        user_id: user_id,
    };
};
export const ErrorDeleteCashService = () => {
    return {
        type: "DELETE_ERROR_SERVICE",
    };
};
export const DeleteCashServiceApi = (id) => {
    return dispatch => {
        dispatch(RequestDeleteCashService());
        API.delete(`/cashService/${id}`).then(res => {
            dispatch(SucessDeleteCashService(id))
        }).catch((error) => {
            dispatch(ErrorDeleteCashService())
        });
    }
};


export const CashServiceEnglishPDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Service Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
        const srNo = "Invoice No: " + data.seqNumber;
        const serviceType = "Service Type: " + data.serviceType;
        const businessName = "Business Location: " + data.businessLocationDetails.businessName;
        const status = "Status: " + data.status;
        const vehicleNumber = "Vehicle Number: " + data.vehicleNumber;
        const vehicleMade = "Vehicle Made: " + data.vehicleMade;
        const vehicleModel = "Vehicle Model: " + data.vehicleModel;
        const customerName = "Customer Name: " + data.customerName;
        const customerNumber = "Customer Number: " + data.customerNumber;
        const customerEmail = "Customer Email: " + data.customerEmail;
        const employeeName = "Employee Name: " + data.employeeDetails.name;
        const date = "Date: " + data.date;
        const reciverName = "Receiver Name: " + data.deliveryPassDetails.reciverName;

        const headers = [[
            "Sr",
            "Product Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const prodactData = _.isEmpty(data.productExpenseData) ? '' : data.productExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "Total", "", "", Math.round(_.sumBy(prodactData, '4') * 100) / 100])
        }
        let ProductContent = {
            startY: 300,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);

        const ServiceHeaders = [[
            "Sr",
            "Service Name",
            "Quantity",
            "Price",
            "Total Sale Value",
        ]];
        const ServiceData = _.isEmpty(data.serviceExpenseData) ? '' : data.serviceExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(ServiceData)) {
            ServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ServiceData, '4') * 100) / 100])
        }
        let ServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ServiceHeaders,
            styles: { fontSize: 11 },
            body: ServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ServiceContent);

        const ExtraServiceHeaders = [[
            "Sr",
            "Description",
            "Quantity",
            "Price",
            "Total Price",
        ]];
        const ExtraServiceData = _.isEmpty(data.extraServiceExpenseData) ? '' : data.extraServiceExpenseData.map((elt, i) => [
            i + 1,
            elt.description,
            elt.quantity,
            elt.price,
            elt.totalPrice,
        ]);
        if (!_.isEmpty(ExtraServiceData)) {
            ExtraServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ExtraServiceData, '4') * 100) / 100])
        }
        let ExtraServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ExtraServiceHeaders,
            styles: { fontSize: 11 },
            body: ExtraServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ExtraServiceContent);


        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyVAT, 150, 73, 0, 20);
        doc.text(companyNtn, 150, 88, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(srNo, 50, 150, 0, 20);
        doc.text(serviceType, 330, 150, 0, 20);
        doc.text(businessName, 50, 170, 0, 20);
        doc.text(status, 330, 170, 0, 20);
        doc.text(vehicleNumber, 50, 190, 0, 20);
        doc.text(vehicleMade, 330, 190, 0, 20);
        doc.text(vehicleModel, 50, 210, 0, 20);
        doc.text(customerName, 330, 210, 0, 20);
        doc.text(customerNumber, 50, 230, 0, 20);
        doc.text(customerEmail, 330, 230, 0, 20);
        doc.text(date, 50, 250, 0, 20);
        doc.text(reciverName, 330, 250, 0, 20);
        doc.text(employeeName, 50, 270, 0, 20);

        doc.text(`Advance Amount = ${data.advance}`, 50, doc.lastAutoTable.finalY + 20);
        doc.text(`Total Expense = ${data.totalExpense}`, 50, doc.lastAutoTable.finalY + 40);
        doc.text(`Vat (5%) = ${data.vatTotal}`, 50, doc.lastAutoTable.finalY + 60);
        doc.text(`Final Total = ${data.finalTotal}`, 50, doc.lastAutoTable.finalY + 80);

        // for (var i = 0; i < 10; i++) {
        //     // doc.text(`Final Total = ${data.finalTotal}`, 50, doc.lastAutoTable.finalY + 60 + (i * 20));
        //     doc.addImage(logo, "PNG", 40 + (i * 80), doc.lastAutoTable.finalY + 60, 80, 80);
        // }

        // _.map(data.images, (value, i) => {
        //     if (value.file.split('.').pop() === 'png') {
        //         doc.addImage(`${baseURL}/assets/${value.file}`, "PNG", 40 + (i * 80), doc.lastAutoTable.finalY + 60, 80, 80);
        //     } else if (value.file.split('.').pop() === 'jpeg') {
        //         doc.addImage(`${baseURL}/assets/${value.file}`, "JPEG", 40 + (i * 80), doc.lastAutoTable.finalY + 60, 80, 80);
        //     } else if (value.file.split('.').pop() === 'jpg') {
        //         doc.addImage(`${baseURL}/assets/${value.file}`, "JPG", 40 + (i * 80), doc.lastAutoTable.finalY + 60, 80, 80);
        //     }
        // })

        doc.setProperties({ title: `Service Detail Report` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const CashServiceArabicPDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        doc.addFileToVFS("Amiri-Regular.ttf", textData);
        doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
        doc.setFont("Amiri");
        const title = "تفاصيل الخدمة";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
        const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
        const srNo = "رقم الفاتورة : " + data.seqNumber;
        const serviceType = "نوع الخدمة: " + data.serviceType;
        const businessName = "موقع العمل: " + data.businessLocationDetails.businessName;
        const status = "حالة: " + data.status;
        const vehicleNumber = "رقم السيارة: " + data.vehicleNumber;
        const vehicleMade = "مركبة مصنوعة: " + data.vehicleMade;
        const vehicleModel = "طراز السيارة: " + data.vehicleModel;
        const customerName = "اسم العميل: " + data.customerName;
        const customerNumber = "رقم العميل: " + data.customerNumber;
        const customerEmail = "بريد إلكتروني للعميل: " + data.customerEmail;
        const employeeName = "اسم الموظف: " + data.employeeDetails.name;
        const date = "تاريخ: " + data.date;
        const reciverName = "اسم المتلقي: " + data.deliveryPassDetails.reciverName;

        const headers = [[
            "رقم التسلسل",
            "اسم المنتج",
            "كمية",
            "سعر",
            "إجمالي قيمة البيع",
        ]];
        const prodactData = _.isEmpty(data.productExpenseData) ? '' : data.productExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "Total", "", "", Math.round(_.sumBy(prodactData, '4') * 100) / 100])
        }
        let ProductContent = {
            startY: 300,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ProductContent);

        const ServiceHeaders = [[
            "رقم التسلسل",
            "اسم الخدمة",
            "كمية",
            "سعر",
            "إجمالي قيمة البيع",
        ]];
        const ServiceData = _.isEmpty(data.serviceExpenseData) ? '' : data.serviceExpenseData.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.quantity,
            elt.salePrice,
            elt.totalSalePrice,
        ]);
        if (!_.isEmpty(ServiceData)) {
            ServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ServiceData, '4') * 100) / 100])
        }
        let ServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ServiceHeaders,
            styles: { fontSize: 11 },
            body: ServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ServiceContent);

        const ExtraServiceHeaders = [[
            "رقم التسلسل",
            "وصف",
            "كمية",
            "سعر",
            "السعر الاجمالي",
        ]];
        const ExtraServiceData = _.isEmpty(data.extraServiceExpenseData) ? '' : data.extraServiceExpenseData.map((elt, i) => [
            i + 1,
            elt.description,
            elt.quantity,
            elt.price,
            elt.totalPrice,
        ]);
        if (!_.isEmpty(ExtraServiceData)) {
            ExtraServiceData.push(["", "Total", "", "", Math.round(_.sumBy(ExtraServiceData, '4') * 100) / 100])
        }
        let ExtraServiceContent = {
            startY: doc.lastAutoTable.finalY + 20,
            startX: 50,
            head: ExtraServiceHeaders,
            styles: { fontSize: 11 },
            body: ExtraServiceData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba", font: 'Amiri', fontSize: 10 },
        };
        doc.autoTable(ExtraServiceContent);

        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyVAT, 150, 73, 0, 20);
        doc.text(companyNtn, 150, 88, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(srNo, 50, 150, 0, 20);
        doc.text(serviceType, 330, 150, 0, 20);
        doc.text(businessName, 50, 170, 0, 20);
        doc.text(status, 330, 170, 0, 20);
        doc.text(vehicleNumber, 50, 190, 0, 20);
        doc.text(vehicleMade, 330, 190, 0, 20);
        doc.text(vehicleModel, 50, 210, 0, 20);
        doc.text(customerName, 330, 210, 0, 20);
        doc.text(customerNumber, 50, 230, 0, 20);
        doc.text(customerEmail, 330, 230, 0, 20);
        doc.text(date, 50, 250, 0, 20);
        doc.text(employeeName, 50, 270, 0, 20);
        doc.text(reciverName, 330, 250, 0, 20);

        doc.text(`المبلغ المقدم = ${data.advance}`, 50, doc.lastAutoTable.finalY + 20);
        doc.text(`إجمالي النفقات = ${data.totalExpense}`, 50, doc.lastAutoTable.finalY + 40);
        doc.text(`حوض = ${data.vatTotal}`, 50, doc.lastAutoTable.finalY + 60);
        doc.text(`المجموع النهائي = ${data.finalTotal}`, 50, doc.lastAutoTable.finalY + 80);

        doc.setProperties({ title: `Service Detail Report` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const requestAddCashServiceDeliveryPass = () => {
    return {
        type: "REQUEST_ADD_CASH_SERVICE_DELIVERY_PASS",
    };
};
export const successAddCashServiceDeliveryPass = (data) => {
    return {
        type: "SUCCESS_ADD_CASH_SERVICE_DELIVERY_PASS",
        payload: data,
    };
};
export const errorAddCashServiceDeliveryPass = () => {
    return {
        type: "ERROR_ADD_CASH_SERVICE_DELIVERY_PASS",
    };
};
export const AddCashDeliveryPassApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCashServiceDeliveryPass());
        API.post(`/cashService/add_deliveryPass`, data).then((res) => {
            dispatch(successAddCashServiceDeliveryPass(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCashServiceDeliveryPass());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddCashServiceExpense = () => {
    return {
        type: "REQUEST_ADD_CASH_SERVICE_EXPENSE",
    };
};
export const successAddCashServiceExpense = (data) => {
    return {
        type: "SUCCESS_ADD_CASH_SERVICE_EXPENSE",
        payload: data,
    };
};
export const errorAddCashServiceExpense = () => {
    return {
        type: "ERROR_ADD_CASH_SERVICE_EXPENSE",
    };
};
export const AddCashExpenseApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCashServiceExpense());
        API.post(`/cashService/add_expense`, data).then((res) => {
            dispatch(successAddCashServiceExpense(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCashServiceExpense());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const resetReducer = () => {
    return {
        type: "RESET_URL",
    };
};


export const requestCashServiceWithStatus = () => {
    return {
        type: "REQUEST_SEARCH_SERVICE_WITH_StATUS",
    };
};
export const successCashServiceWithStatus = (data) => {
    return {
        type: "SUCCESS_SEARCH_SERVICE_WITH_StATUS",
        payload: data,
    };
};
export const errorCashServiceWithStatus = () => {
    return {
        type: "ERROR_SEARCH_SERVICE_WITH_StATUS",
    };
};
export const GetCashServiceWithStatusApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestCashServiceWithStatus());
        API.post(`/cashService/get_cashService_with_status`, data).then((res) => {
            let getData = res.data;
            dispatch(successCashServiceWithStatus(getData));
        }).catch((error) => {
            dispatch(errorCashServiceWithStatus());
        });
    };
};


export const DeliveryPassReportApi = (id) => {
    return (dispatch) => {
        dispatch(requestCashServiceDataWithId());
        API.get(`/cashService/${id.mainServiceId}`).then((res) => {
            let data = res.data.data[0];

            if (id.type === 'Arabic') {
                const unit = "pt";
                const size = "A4";
                const orientation = "potrait";
                const doc = new jsPDF(orientation, unit, size);
                doc.setFontSize(11);
                doc.addFileToVFS("Amiri-Regular.ttf", textData);
                doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
                doc.setFont("Amiri");
                const title = "تقرير تصريح التسليم";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
                const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
                const date = "تاريخ: " + data.date;
                const deliverDate = "تاريخ التسليم او الوصول: " + data.deliveryPassDetails.deliverDate;
                const customerName = "اسم العميل: " + data.customerName;
                const vehicleNumber = "رقم السيارة: " + data.vehicleNumber;
                const residenceID = "رقم الإقامة: " + data.deliveryPassDetails.residenceID;
                const reciverName = "اسم المتلقي: " + data.deliveryPassDetails.reciverName;

                doc.addImage(logo, "PNG", 40, 15, 80, 80);
                doc.text(companyName, 150, 40, 0, 20);
                doc.setFontSize(13);
                doc.text(companyAddress, 150, 58, 0, 20);
                doc.text(companyVAT, 150, 73, 0, 20);
                doc.text(companyNtn, 150, 88, 0, 20);
                doc.setFontSize(14);
                doc.text(title, 240, 120);
                doc.text(date, 50, 150, 0, 20);
                doc.text(deliverDate, 50, 170, 0, 20);
                doc.text(customerName, 50, 190, 0, 20);
                doc.text(vehicleNumber, 50, 210, 0, 20);
                doc.text(residenceID, 50, 230, 0, 20);
                doc.text(reciverName, 50, 250, 0, 20);

                doc.setProperties({ title: `Delivery Pass Report` });
                const blob = doc.output("blob");
                window.open(URL.createObjectURL(blob));
            } else {
                const unit = "pt";
                const size = "A4";
                const orientation = "potrait";
                const doc = new jsPDF(orientation, unit, size);
                doc.setFontSize(11);
                const title = "Delivery Pass Details";
                const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
                const companyVAT = `${process.env.REACT_APP_COMPANY_VAT}`;
                const date = "Date: " + data.date;
                const deliverDate = "Delivery Date: " + data.deliveryPassDetails.deliverDate;
                const customerName = "Customer Name: " + data.customerName;
                const vehicleNumber = "Vehicle Number: " + data.vehicleNumber;
                const residenceID = "Residence ID: " + data.deliveryPassDetails.residenceID;
                const reciverName = "Receiver Name: " + data.deliveryPassDetails.reciverName;

                doc.addImage(logo, "PNG", 40, 15, 80, 80);
                doc.text(companyName, 150, 40, 0, 20);
                doc.setFontSize(13);
                doc.setFont("times", "normal");
                doc.text(companyAddress, 150, 58, 0, 20);
                doc.text(companyVAT, 150, 73, 0, 20);
                doc.text(companyNtn, 150, 88, 0, 20);
                doc.setFontSize(14);
                doc.setFont("times", "bold");
                doc.text(title, 240, 120);
                doc.setFont("times", "normal");
                doc.text(date, 50, 150, 0, 20);
                doc.text(deliverDate, 50, 170, 0, 20);
                doc.text(customerName, 50, 190, 0, 20);
                doc.text(vehicleNumber, 50, 210, 0, 20);
                doc.text(residenceID, 50, 230, 0, 20);
                doc.text(reciverName, 50, 250, 0, 20);

                doc.setProperties({ title: `Delivery Pass Report` });
                const blob = doc.output("blob");
                window.open(URL.createObjectURL(blob));
            }

            dispatch(successCashServiceDataWithId(data));
        }).catch((error) => {
            dispatch(errorCashServiceDataWithId(error));
        });
    };
};


export const setEditFields = (data) => {
    return {
        type: "SET_EDIT_CASH_FIELDS",
        payload: data
    };
}