import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateUserRes, deleteUserRes } from "./user.actions";
// import _ from "lodash";
import { setHeading } from "../../../accounts/account.actions";


const ViewUser = (props) => {

  useEffect(() => {
    props.setHeading("User List")
  }, []);

  return (
    <>
      {props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>

          <div className="main">
            <MaterialTable
              style={{ zIndex: 0 }}
              title="User"
              columns={[
                { title: "Company", field: "companyDetails.companyName", editable: 'never' },
                { title: "Business Locaton", field: "businessLocationDetails.businessName", editable: 'never' },
                { title: "User Name", field: "userName" },
                { title: "Passward", field: "hash" },
                { title: "Phone", field: "lastName" },
                { title: "Role", field: "role" },
                { title: "Last Login", field: "lastLogin", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/users/?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: 'sticky',
                  top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.UpdateUser(newData, oldData);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteUser(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    deleteUser: (id) => dispatch(deleteUserRes(id)),
    UpdateUser: (newData, oldData) => dispatch(UpdateUserRes(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
