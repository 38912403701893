import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { baseURL, headers } from "../../../../../global/api";
import { UpdateEmployeeApi, DelateEmployeeApi } from "./employeeAction";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";


const EmployeeView = (props) => {
    useEffect(() => {
        props.setHeading("Employee List")
    }, []);
    return (
        <>
            {props.isFetchingEmployee ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            style={{ zIndex: 0 }}
                            title="Employee List"
                            columns={[
                                { title: "Company", field: "companyDetails.companyName", editable: 'never' },
                                { title: "Business Locaton", field: "businessLocationDetails.businessName", editable: 'never' },
                                { title: "Name", field: "name" },
                                { title: "Phone", field: "phone" },
                                { title: "Email", field: "email" },
                                { title: "Address", field: "address" },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/employee/?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky',
                                    top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateEmployeeApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DelateEmployeeApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DelateEmployeeApi: (id) => dispatch(DelateEmployeeApi(id)),
        UpdateEmployeeApi: (newData, oldData) => dispatch(UpdateEmployeeApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingEmployee: state.EmployeeReducer.isFetchingEmployee,
    employeeList: state.EmployeeReducer.employeeList,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeView);