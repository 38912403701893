import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { deleteProduct, updateProduct, updateProductStatusApi } from "./productAction";
import { setHeading } from "../../../accounts/account.actions";
// import _ from 'lodash'
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";
const tableRef = React.createRef()


const ViewProduct = (props) => {

  useEffect(() => {
    props.setHeading("Product List");
  }, []);

  return (
    <>
      {props.isFetchingProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              tableRef={tableRef}
              title="Product List"
              columns={[
                { title: 'Image', field: 'images', editable: 'never', render: rowData => <img src={`${baseURL}/assets/${rowData.images}`} alt="PRO" style={{ width: 50, borderRadius: '50%' }} /> },
                { title: "Business Location", field: "businessLocationDetails.businessName", editable: 'never' },
                { title: 'Product Code', field: 'code' },
                { title: "Product Name", field: "name", },
                { title: "Opening Balance", field: "openingBalance" },
                { title: "Closing Balance", field: "productBalance" },
                { title: "Description", field: "desc" },
                { title: "Purchase Price", field: "purchasePrice" },
                { title: "Sale Price", field: "salePrice" },
                { title: "Status", field: "status" },
                { title: "Date", field: "productDate", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/products?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      resImage: result.resImage,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                exportPdf: (columns, data) => {
                  const unit = "pt";
                  const size = "A4";
                  const orientation = 'landscape';
                  const doc = new jsPDF(orientation, unit, size);
                  const title = "Product List";
                  const columnTitles = [
                    { title: 'Business Location', field: 'businessLocationDetails.businessName', render: rowData => rowData.businessLocationDetails.businessName },
                    { title: 'Product Code', field: 'code', render: rowData => rowData.code },
                    { title: "Product Name", field: "name", render: rowData => rowData.name },
                    { title: "Opening Balance", field: "openingBalance", render: rowData => rowData.openingBalance },
                    { title: "Closing Balance", field: "productBalance", render: rowData => rowData.productBalance },
                    { title: "Description", field: "desc", render: rowData => rowData.desc },
                    { title: "Purchase Price", field: "purchasePrice", render: rowData => rowData.purchasePrice },
                    { title: "Sale Price", field: "salePrice", render: rowData => rowData.salePrice },
                    { title: "Status", field: "status", render: rowData => rowData.status },
                    { title: "Date", field: "productDate", render: rowData => rowData.productDate },
                  ].map(columnDef => columnDef.title);
                  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                  const companyNtn = `${process.env.REACT_APP_COMPANY_PHONE}`;
                  const pdfData = data.map((rowData) => [
                    rowData.businessLocationDetails.businessName,
                    rowData.code, rowData.name,
                    rowData.openingBalance,
                    rowData.productBalance,
                    rowData.desc,
                    rowData.purchasePrice,
                    rowData.salePrice,
                    rowData.status,
                    rowData.productDate,
                  ]);
                  let content = {
                    startY: 120,
                    startX: 2,
                    head: [columnTitles],
                    styles: { fontSize: 11 },
                    body: pdfData,
                    theme: "grid",
                    headStyles: { fillColor: "#2980ba" },
                  };
                  doc.addImage(logo, "PNG", 40, 15, 80, 80);
                  doc.text(companyName, 150, 40, 0, 20);
                  doc.setFontSize(13);
                  doc.setFont("times", "normal");
                  doc.text(companyAddress, 150, 60, 0, 20);
                  doc.text(companyNtn, 150, 80, 0, 20);
                  doc.setFontSize(14);
                  doc.setFont("times", "bold");
                  doc.text(title, 400, 100);
                  doc.setFont("times", "normal");
                  doc.autoTable(content);

                  doc.setProperties({ title: `Product List` });
                  var blob = doc.output("blob");
                  window.open(URL.createObjectURL(blob));
                },
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateProduct(newData, oldData);
                    }, 600);
                  }),
                isDeletable: false,
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteProduct(oldData._id);
                    }, 600);
                  }),
              }}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                (rowData) => ({
                  disabled: rowData.status === false ? false : true,
                  icon: () => <TaskAltIcon />,
                  tooltip: "Active Product",
                  onClick: (event, rowData) => {
                    const status = true
                    props.updateProductStatusApi(status, rowData._id);
                  },
                }),
                (rowData) => ({
                  disabled: rowData.status === true ? false : true,
                  icon: () => <BlockIcon />,
                  tooltip: "Block Product",
                  onClick: (event, rowData) => {
                    const status = false
                    props.updateProductStatusApi(status, rowData._id);
                  },
                }),
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    updateProduct: (newData, oldData) => dispatch(updateProduct(newData, oldData)),
    updateProductStatusApi: (newData, oldData) => dispatch(updateProductStatusApi(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);