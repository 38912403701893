const initialState = {
    addEmployee: [],
    isAddingEmployee: false,
    employeeList: [],
    isFetchingEmployee: false,
    isDeletingEmployee: false,
}
const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: true };
        case "SUCCESS_ADD_EMPLOYEE":
            return { ...state, addEmployee: action.payload, isAddingEmployee: false, };
        case "ERROR_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: false };

        case "REQUEST_GET_EMPLOYEE":
            return { ...state, isFetchingEmployee: true };
        case "SUCCESS_GET_EMPLOYEE":
            return { ...state, employeeList: action.payload.data, isFetchingEmployee: false, };
        case "ERROR_GET_EMPLOYEE":

            return { ...state };
        case "REQUEST_DELETE_USER_RES":
            return { ...state, isDeletingEmployee: false };
        case "SUCCESS_DELETE_USER_RES":
            return { ...state, isDeletingEmployee: true };
        case "ERROR_DELETE_USER_RES":
            return { ...state, isDeletingEmployee: false };


        default: return state;
    }
};
export default EmployeeReducer;